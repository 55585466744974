import Typography from '@mui/material/Typography';
import {
    Box,
    Breadcrumbs,
    Grid, IconButton, LinearProgress,
    Link,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, Tooltip
} from '@mui/material';
import {FC} from "react";
import DashboardTitle from "../../components/DashboardTitle";
import {Link as RouterLink} from "react-router-dom";
import Paths from "../../paths";
import {BaseCard, TextBaseCard} from "../../components/BaseCard";
import Markdown from "react-markdown";
import {Info} from "@mui/icons-material";

interface MarketsRowProps {
    name: string;
    revenue_opportunity_score: number;
    logistical_ease_score: number;
    operational_readiness_score: number;
    overall_score: number;

}

interface TableCellWithScoreProps {
    score: number;
    tooltipText: string;
}


const TableCellWithScore: FC<TableCellWithScoreProps> = ({score, tooltipText}) => {
    let color: 'secondary' | 'error' | 'warning' | 'success' = 'secondary';
    if (score >= 0 && score <= 30) {
        color = 'error'
    } else if (score > 30 && score <= 70) {
        color = 'warning'
    } else if (score > 70 && score <= 100) {
        color = 'success'
    }
    return <TableCell>
        <Box sx={{display: 'flex', alignItems: 'center', width: '100%'}}>
            {/* Fixed-width LinearProgress */}
            <Box sx={{width: 40, mr: 1}}>
                <LinearProgress variant="determinate" value={score} color={color}/>
            </Box>

            {/* Text and Tooltip Icon */}
            <Box sx={{display: 'flex', alignItems: 'center', flexWrap: 'wrap'}}>
                <Typography variant="body2" noWrap>
                    {score}/100
                </Typography>
                <Tooltip
                    title={tooltipText}>
                    <IconButton>
                        <Info/>
                    </IconButton>
                </Tooltip>
            </Box>
        </Box>
    </TableCell>

}

const Row: FC<MarketsRowProps> = ({
                                      name,
                                      revenue_opportunity_score,
                                      logistical_ease_score,
                                      operational_readiness_score,
                                      overall_score
                                  }) => {
    return <TableRow>
        <TableCell key={1}>{name}</TableCell>

        <TableCellWithScore score={revenue_opportunity_score} tooltipText={"These markets are available to all cattle producers"}/>
        <TableCellWithScore score={logistical_ease_score}
                            tooltipText={"These markets are available to all cattle producers"}/>
        <TableCellWithScore score={operational_readiness_score}
                            tooltipText={"These markets are available to all cattle producers"}/>
        <TableCellWithScore score={overall_score} tooltipText={"These markets are available to all cattle producers"}/>
    </TableRow>
}

const Enterprises: FC = () => {

    return (
        <>
            <Grid container spacing={2.5}>
                <Grid item width={'100%'}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link component={RouterLink} to={Paths.FULL_SURVEY.INDEX}>
                            Full Survey Report
                        </Link>
                        <Typography color="textPrimary">
                            Enterprises
                        </Typography>
                    </Breadcrumbs>
                </Grid>
                <DashboardTitle title={"Enterprises"}/>
                <Grid item width={'100%'}>
                    <TextBaseCard title={"Summary"}>
                        <Markdown>
                            {`The Bennington farm, currently engaged in organic regenerative beef production and pasture management, has potential for both short-term and long-term enterprise additions to diversify and enhance sustainability. In the short term, direct-to-consumer beef sales, organic poultry production, and agrotourism could provide immediate benefits and revenue streams. Looking further ahead, the farm could invest in renewable energy projects, expand into organic crop production, develop value-added products like organic beef jerky, and engage in conservation efforts to improve biodiversity. These initiatives could not only increase profitability but also strengthen the farm's commitment to environmental stewardship and community engagement.`}
                        </Markdown>
                    </TextBaseCard>
                </Grid>
                <Grid item width={'100%'}>
                    <TextBaseCard title={"Current Enterprises"}>
                        <Markdown>
                            {`
- **Organic Regenerative Beef Production**: The farm is certified organic and is being used as a pilot for a larger project to establish an organic regenerative brand of beef. It operates with a focus on sustainable and organic farming practices.
- **Pasture Management**: The farm has been involved in seeding a pasture mix to support its beef production. Despite challenges such as drought affecting the establishment of the pasture stand, efforts are ongoing to improve pasture quality and resilience.
`}
                        </Markdown>
                    </TextBaseCard>
                </Grid>
                <Grid item width={'50%'}>
                    <BaseCard title={"Short-term Potential Enterprises"}>
                        <Box
                            sx={{p: '24px'}}
                        >
                            <Markdown>
                                {`
- **Forage Crop Production**: Given the existing focus on pasture management and beef production, expanding into forage crop production like alfalfa or clover could enhance feed availability and soil health. This would complement the cattle operation by providing high-quality feed and further supporting soil regeneration efforts.
- **Direct-to-Consumer Beef Sales**: Building on the organic regenerative beef operation, the farm could explore direct sales channels such as a CSA (Community Supported Agriculture) model, farmers' markets, or an online storefront. This would open up revenue streams and build brand recognition among local consumers.
- **Organic Poultry Production:** Introducing a small-scale organic poultry operation could diversify the farm's offerings. Chickens can also contribute to pest control and soil fertility through their natural foraging.
`}
                            </Markdown>
                        </Box>
                        <TableContainer
                            sx={{
                                width: '100%',
                                overflowX: 'auto',
                                position: 'relative',
                                display: 'block',
                                maxWidth: '100%',
                            }}
                        >
                            <Table aria-labelledby="tableTitle" sx={{
                                maxWidth: '100%',
                                tableLayout: "auto",
                                // width: "auto"
                            }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell key={1}>Enterprise</TableCell>
                                        <TableCell key={3}>Revenue Opportunity</TableCell>
                                        <TableCell key={4}>Logistical Ease</TableCell>
                                        <TableCell key={5}>Operational Readiness</TableCell>
                                        <TableCell key={6}>Overall Score</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <Row name={"D2C Beef Sales"} logistical_ease_score={50}
                                         operational_readiness_score={90} overall_score={80} revenue_opportunity_score={90}/>
                                    <Row name={"Organic Poultry"} logistical_ease_score={70}
                                         operational_readiness_score={70} overall_score={70} revenue_opportunity_score={70}/>
                                    <Row name={"Forage Crop Production"} logistical_ease_score={90}
                                         operational_readiness_score={100} overall_score={60} revenue_opportunity_score={20}/>
                                </TableBody>
                            </Table>
                        </TableContainer>


                    </BaseCard>
                </Grid>
                <Grid item width={'50%'}>
                    <TextBaseCard title={"Long-term Potential Enterprises"}>
                        <Markdown>
                            {`
- **Agroforestry or Silvopasture Systems**: Integrating trees or shrubs into the farm's grazing systems can provide multiple benefits, including enhanced biodiversity, improved animal welfare (through shade provision), and additional income streams from timber or nut/fruit production.
- **Renewable Energy Projects**: Given the farm's land availability, investing in renewable energy projects like solar panels or wind turbines could offer long-term sustainability benefits and potential income through energy production.
- **Value-Added Products**: Developing value-added products such as organic beef jerky, sausages, or other processed meat products could leverage the farm's organic status and offer higher profit margins than raw beef sales alone. This could also include creating a brand around these products for direct sales.
`}
                        </Markdown>
                    </TextBaseCard>
                </Grid>
            </Grid>
        </>
    )
        ;
};

export default Enterprises;