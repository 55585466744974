import React, {FC, useState} from "react";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import {useTheme} from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import {DRAWER_WIDTH} from "../config";
import {NavLink, Outlet, useLocation, useNavigate} from "react-router-dom";
import {useAuth} from "../auth/AuthContext";
import Container from "@mui/material/Container/Container";
import Typography from "@mui/material/Typography";
import Paths from "../paths";
import {AccountCircle} from "@mui/icons-material";
import {Button, Divider, Drawer, Link, List, ListItemButton, ListItemText, Menu, MenuItem, Stack} from "@mui/material";

const INDENT_DISTANCE = 28;

const Admin: FC = () => {
    const theme = useTheme();
    const {logout} = useAuth();
    const location = useLocation(); // This hook returns the current location object
    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        // Perform logout operations here
        console.log('Logging out');
        logout();
        console.log('...and sending home')
        navigate('/')
    };

    const NavDrawer = () => {
        return <Box component="nav">
            <Drawer
                variant="permanent"
                anchor="left"
                sx={{
                    display: {xs: 'none', sm: 'block'},
                    boxSizing: 'border-box',
                    width: DRAWER_WIDTH
                }}
            >
                <Container
                    sx={{
                        width: DRAWER_WIDTH,
                        paddingX: {xs: '0px', sm: '0px'},
                    }}
                >
                    <Toolbar disableGutters={true} sx={{paddingLeft: '24px'}}>
                        <Typography variant="h4" component="div" sx={{flexGrow: 1}}>
                            Rooted Ag
                        </Typography>
                    </Toolbar>


                    <List sx={{paddingTop: 0}}>
                        {/* Context Report is a single link*/}
                        <Link key={"Operations"} component={NavLink} to={Paths.ADMIN.OPERATIONS} underline="none"
                              color="inherit">
                            <ListItemButton sx={{pl: `${INDENT_DISTANCE}px`}}
                                            selected={location.pathname === Paths.ADMIN.OPERATIONS}>
                                <ListItemText primary={"Operations"}/>
                            </ListItemButton>
                        </Link>
                        <Link key={"Customers"} component={NavLink} to={Paths.ADMIN.CUSTOMERS} underline="none"
                              color="inherit">
                            <ListItemButton sx={{pl: `${INDENT_DISTANCE}px`}}
                                            selected={location.pathname === Paths.ADMIN.CUSTOMERS}>
                                <ListItemText primary={"Customers"}/>
                            </ListItemButton>
                        </Link>
                        <Divider/>
                        <Link key={"Operators"} component={NavLink} to={Paths.ADMIN.OPERATORS} underline="none"
                              color="inherit">
                            <ListItemButton sx={{pl: `${INDENT_DISTANCE}px`}}
                                            selected={location.pathname === Paths.ADMIN.OPERATORS}>
                                <ListItemText primary={"Operators"}/>
                            </ListItemButton>
                        </Link>
                        <Link key={"Markets"} component={NavLink} to={Paths.ADMIN.MARKETS} underline="none"
                              color="inherit">
                            <ListItemButton sx={{pl: `${INDENT_DISTANCE}px`}}
                                            selected={location.pathname === Paths.ADMIN.MARKETS}>
                                <ListItemText primary={"Markets"}/>
                            </ListItemButton>
                        </Link>
                        <Link key={"Funding"} component={NavLink} to={Paths.ADMIN.FUNDING} underline="none"
                              color="inherit">
                            <ListItemButton sx={{pl: `${INDENT_DISTANCE}px`}}
                                            selected={location.pathname === Paths.ADMIN.FUNDING}>
                                <ListItemText primary={"Funding"}/>
                            </ListItemButton>
                        </Link>
                        <Link key={"Training"} component={NavLink} to={Paths.ADMIN.TRAINING} underline="none"
                              color="inherit">
                            <ListItemButton sx={{pl: `${INDENT_DISTANCE}px`}}
                                            selected={location.pathname === Paths.ADMIN.TRAINING}>
                                <ListItemText primary={"Training"}/>
                            </ListItemButton>
                        </Link>
                    </List>
                </Container>
            </Drawer>
        </Box>
    }

    return (
        <>
            <Box sx={{display: 'flex', width: '100%'}}>
                <AppBar
                    sx={{
                        bgcolor: 'grey.0',
                        borderBottomColor: 'grey.200',
                        borderBottomWidth: '1px',
                        borderBottomStyle: 'solid',
                        marginLeft: {xs: 0, sm: DRAWER_WIDTH},
                        width: {xs: '100%', sm: `calc(100% - ${DRAWER_WIDTH}px)`},
                        color: theme.palette.text.primary,
                        boxShadow: 'none'
                    }}
                >
                    <Toolbar>
                        <Box sx={{flexGrow: 1}}/>
                        <div>
                            <Button
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleMenu}
                                color="inherit"
                            >
                                <Stack direction="row" spacing={1} alignItems="center">
                                    <Typography variant={"h4"}>Admin</Typography>
                                    <AccountCircle/>
                                </Stack>
                            </Button>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                            >
                                <MenuItem onClick={handleLogout}>Logout</MenuItem>
                            </Menu>
                        </div>
                    </Toolbar>
                </AppBar>
                <NavDrawer/>
                <Box
                    component={'main'}
                    sx={{
                        width: '100%',
                        padding: '24px'
                    }}
                >
                    <Toolbar/>
                    <Outlet/>
                </Box>
            </Box>
        </>
    )
}

export default Admin
