const formatCurrencyWithParens = (value: number, includeDecimal: boolean = true): string => {
    const formattedValue = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: includeDecimal ? 2 :0,
        maximumFractionDigits: 2,
    }).format(Math.abs(value));

    return value < 0 ? `(${formattedValue})` : formattedValue;
};

export default formatCurrencyWithParens;