import {FC} from "react";
import {Box, IconButton, LinearProgress, Tooltip} from "@mui/material";
import Typography from "@mui/material/Typography";
import {Info} from "@mui/icons-material";

interface LinearProgressWithScoreAndTooltipProps {
    score: number;
    tooltipText: string | null;
}


const LinearProgressWithScoreAndTooltip: FC<LinearProgressWithScoreAndTooltipProps> = ({score, tooltipText=null}) => {
    let color: 'secondary' | 'error' | 'warning' | 'success' = 'secondary';
    if (score >= 0 && score <= 30) {
        color = 'error'
    } else if (score > 30 && score <= 70) {
        color = 'warning'
    } else if (score > 70 && score <= 100) {
        color = 'success'
    }
    return <Box sx={{display: 'flex', alignItems: 'center', width: '100%'}}>
        {/* Fixed-width LinearProgress */}
        <Box sx={{width: 100, mr: 1}}>
            <LinearProgress variant="determinate" value={score} color={color}/>
        </Box>

        {/* Text and Tooltip Icon */}
        <Box sx={{display: 'flex', alignItems: 'center', flexWrap: 'wrap'}}>
            <Typography variant="body2" noWrap>
                {score}/100
            </Typography>
            {
                tooltipText
                    ?
                    <Tooltip
                        title={tooltipText}>
                        <IconButton>
                            <Info/>
                        </IconButton>
                    </Tooltip>
                    :
                    <></>
            }
        </Box>
    </Box>
}
export default LinearProgressWithScoreAndTooltip
