import React, {useState} from 'react';
import {useDropzone} from 'react-dropzone';
import {Box, Typography} from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import {styled} from '@mui/material/styles';
import Button from "@mui/material/Button";

// Define the props type for the component
interface DropzoneProps {
    onFileUpload: (file: File | null) => void;
    instructions: string;
}

const getColor = (props: { isDragActive: boolean; isDragAccept: boolean; isDragReject: boolean }) => {
    if (props.isDragActive) {
        return '#00e676';
    }
    if (props.isDragAccept) {
        return '#00e676';
    }
    if (props.isDragReject) {
        return '#ff1744';
    }
    return '#eeeeee';
};

const StyledBox = styled(Box, {
    shouldForwardProp: (prop) => prop !== 'isDragActive' && prop !== 'isDragAccept' && prop !== 'isDragReject',
})<{ isDragActive: boolean; isDragAccept: boolean; isDragReject: boolean }>(({theme, ...props}) => ({
    padding: theme.spacing(4),
    border: `2px dashed ${getColor(props)}`,
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.secondary,
    textAlign: 'center',
    cursor: 'pointer',
}));

const DropzoneSingleFile: React.FC<DropzoneProps> = ({onFileUpload, instructions}) => {
    const [file, setFile] = useState<File | null>(null);

    const {getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject} = useDropzone({
        onDrop: acceptedFiles => {
            console.log('Accepted files:', acceptedFiles);
            setFile(acceptedFiles[0]);  // Assuming single file upload
            onFileUpload(acceptedFiles[0]);
        }
    });

    const handleRemoveFile = () => {
        setFile(null);  // Clear the local state
        onFileUpload(null);  // Inform parent component to clear the file
    };

    return (
        <StyledBox {...getRootProps({isDragActive, isDragAccept, isDragReject})}>
            <input {...getInputProps()} />
            {!file && (
                <>
                    <Typography variant="h5">{instructions}</Typography>
                    <CloudUploadIcon style={{fontSize: 30, marginTop: 20}}/>
                </>
            )}
            {file && (
                <Box>
                    <Typography variant="h6">File: {file.name}</Typography>
                    <Button variant="outlined" color="secondary" onClick={handleRemoveFile}>
                        Remove File
                    </Button>
                </Box>
            )}
        </StyledBox>
    );
};

export default DropzoneSingleFile;
