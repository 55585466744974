import {FC} from "react";
import {TableCell} from "@mui/material";
import LinearProgressWithScoreAndTooltip from "./LinearProgressWithScoreAndTooltop";

interface TableCellWithScoreProps {
    score: number;
    tooltipText?: string;
}

const TableCellWithScore: FC<TableCellWithScoreProps> = ({score, tooltipText=null}) => {
    return <TableCell>
                <LinearProgressWithScoreAndTooltip score={score} tooltipText={tooltipText} />
    </TableCell>
}

export default TableCellWithScore