import {Grid} from "@mui/material";
import Typography from "@mui/material/Typography";
import {FC} from "react";

interface DashboardTitle {
    title: string
}

const DashboardTitle: FC<DashboardTitle> = ({title}) => {
    return <>
        <Grid item width={'100%'}>
            <Typography variant="h2">{title}</Typography>
        </Grid>
    </>
}

export default DashboardTitle
