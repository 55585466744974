import {Button, Chip, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import {BaseCard} from "../../components/BaseCard";
import {convertKeysToCamelCase, customerFullName} from "../../utils/formatting";
import {useEffect, useState} from "react";
import {Customer} from "../../types/customer";
import axios from "../../services/APIService";

const Customers = () => {
    const [customerList, setCustomerList] = useState<Customer[]>([])

    useEffect(() => {
        axios.get('/accounts/customer_list/')
            .then(response => {
                setCustomerList(response.data.map((customer: any) => convertKeysToCamelCase(customer)))
            })
    }, [])

    return <Grid item width={'100%'}>
        <BaseCard title={"Customers"}>
            <TableContainer
                sx={{
                    width: '100%',
                    overflowX: 'auto',
                    position: 'relative',
                    display: 'block',
                    maxWidth: '100%',
                }}
            >
                <Table aria-labelledby="tableTitle" sx={{
                    maxWidth: '100%',
                    tableLayout: "auto",
                    // width: "auto"
                }}>
                    <TableHead>
                        <TableRow>
                            <TableCell key={1}>Name</TableCell>
                            <TableCell key={3}>Operations</TableCell>
                            <TableCell key={4}>Hub</TableCell>
                            <TableCell key={5}>Status</TableCell>
                            <TableCell key={6}>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {customerList.map((customer, index) => {
                            return <TableRow key={index}>
                                <TableCell key={1}>{customerFullName(customer)}</TableCell>
                                <TableCell key={3}>
                                    {customer.operations.map((operation, index) => {
                                        return <Chip label={operation.name} sx={{marginRight: 1}} key={index}/>
                                    })}
                                </TableCell>
                                <TableCell key={4}>Bourbon County, KS</TableCell>
                                <TableCell key={5}>Active</TableCell>
                                <TableCell key={6}><Button disabled={true}>No Action Needed</Button>
                                </TableCell>
                            </TableRow>
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </BaseCard>
    </Grid>
}

export default Customers