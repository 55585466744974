import './App.css';
import {AuthProvider} from "./auth/AuthContext";
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import Index from "./pages/Index";
import ThemeCustomization from "./themes";
import ContextReport from "./pages/dashboard/ContextReport";
import Paths from "./paths"
import "@fontsource/public-sans"; // Defaults to weight 400
import "@fontsource/public-sans/400.css"; // Specify weight
import "@fontsource/public-sans/400-italic.css";
import Dashboard from "./layout/Dashboard";
import SoilHealth from "./pages/dashboard/SoilHealth";
import Infrastructure from "./pages/dashboard/Infrastructure";
import Markets from "./pages/dashboard/Markets";
import Enterprises from "./pages/dashboard/Enterprises";
import WeatherAndClimate from "./pages/dashboard/WeatherAndClimate"; // Specify weight and style
import Finances from 'pages/dashboard/Finances';
import Operators from "./pages/dashboard/Operators";
import Funding from "./pages/dashboard/Funding";
import {CustomerProvider} from "./contexts/CustomerContext";
import {OperationProvider} from "./contexts/OperationContext";
import Admin from "./layout/Admin";
import Customers from "./pages/admin/Customers";
import Operations from "./pages/admin/Operations";
import PlanningOverview from "./pages/dashboard/planning/PlanningOverview";
import PlanningToday from "./pages/dashboard/planning/PlanningToday";
import PlanningFinances from "./pages/dashboard/planning/PlanningFinances";
import PlanningOperations from "./pages/dashboard/planning/PlanningOperations";
import AdminWrapper from "./layout/AdminWrapper";
import ScrollToTop from "./layout/ScrollToTop";
import OpportunityOverview from "./pages/dashboard/OpportunityOverview";
import Tracking from "./pages/dashboard/results/Tracking";
import FinancialResults from "./pages/dashboard/results/FinancialResults";
import SoilHealthResults from "./pages/dashboard/results/SoilHealthResults";


function App() {
    return (
        <ThemeCustomization>
            <AuthProvider>
                <CustomerProvider>
                    <OperationProvider>
                        <AdminWrapper>
                            <BrowserRouter>
                                <ScrollToTop/>
                                <Routes>
                                    <Route path={Paths.INDEX} element={<Index/>}/>
                                    <Route path={Paths.HOME} element={<Navigate replace to={Paths.CONTEXT_REPORT}/>}/>
                                    <Route path={Paths.ADMIN.INDEX} element={<Admin/>}>
                                        <Route index element={<Navigate replace to={Paths.ADMIN.OPERATIONS}/>}/>
                                        <Route path={Paths.ADMIN.CUSTOMERS} element={<Customers/>}/>
                                        <Route path={Paths.ADMIN.OPERATIONS} element={<Operations/>}/>
                                    </Route>
                                    <Route path={Paths.DASHBOARD} element={<Dashboard/>}>
                                        <Route index element={<Navigate replace to={Paths.CONTEXT_REPORT}/>}/>
                                        <Route path={Paths.CONTEXT_REPORT} element={<ContextReport/>}/>

                                        <Route path={Paths.FULL_SURVEY.SOIL_HEALTH} element={<SoilHealth/>}/>
                                        <Route path={Paths.FULL_SURVEY.INFRASTRUCTURE} element={<Infrastructure/>}/>
                                        <Route path={Paths.FULL_SURVEY.MARKETS} element={<Markets/>}/>
                                        <Route path={Paths.FULL_SURVEY.WEATHER_CLIMATE} element={<WeatherAndClimate/>}/>
                                        <Route path={Paths.FULL_SURVEY.ENTERPRISES} element={<Enterprises/>}/>
                                        <Route path={Paths.FULL_SURVEY.FINANCES} element={<Finances/>}/>
                                        <Route path={Paths.FULL_SURVEY.FUNDING} element={<Funding/>}/>
                                        <Route path={Paths.FULL_SURVEY.OPERATORS} element={<Operators/>}/>

                                        <Route path={Paths.PLANNING.OVERVIEW} element={<PlanningOverview/>}/>
                                        <Route path={Paths.PLANNING.OPPORTUNITIES} element={<OpportunityOverview/>}/>
                                        <Route path={Paths.PLANNING.OPERATIONS} element={<PlanningOperations/>}/>
                                        <Route path={Paths.PLANNING.TODAY} element={<PlanningToday/>}/>
                                        <Route path={Paths.PLANNING.FINANCES} element={<PlanningFinances/>}/>

                                        <Route path={Paths.RESULTS.TRACK} element={<Tracking/>}/>
                                        <Route path={Paths.RESULTS.SOIL_HEALTH} element={<SoilHealthResults/>}/>
                                        <Route path={Paths.RESULTS.FINANCIAL} element={<FinancialResults/>}/>
                                    </Route>
                                </Routes>
                            </BrowserRouter>
                        </AdminWrapper>
                    </OperationProvider>
                </CustomerProvider>
            </AuthProvider>
        </ThemeCustomization>
    );
}

export default App;
