import Map from '../../../components/Map';
import {
    Breadcrumbs,
    Card,
    Grid,
    Link,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from '@mui/material';
import {FC, useEffect, useState} from "react";
import axios from "../../../services/APIService";
import {LatLngCoordinatePathType, LatLngCoordinateType, PolygonData, RawCoordinatePathType} from "../../../types/geo";
import {rawTolatLngCoord, rawToLatLngPath} from "../../../utils/geo";
import {useOperation} from "../../../contexts/OperationContext";
import {SubDivision} from "../../../types/planning/geographical";
import Typography from "@mui/material/Typography";
import {Link as RouterLink} from "react-router-dom";
import Paths from "../../../paths";
import DashboardTitle from "../../../components/DashboardTitle";
import {SoilAnalysisType, SoilHealthReportType} from "../../../types/soilTest";
import {ColorProps} from "../../../types/extended";
import Dot from "../../../components/Dot";

const AnalysisResult: FC<{ value?: number, rating: string }> = ({value, rating}) => {
    let color: ColorProps;
    let title: string;

    switch (rating) {
        case 'M':
            color = 'warning';
            title = 'Constant';
            break;
        case "VH":
            color = 'warning';
            title = 'Constant';
            break;
        case "H":
            color = 'warning';
            title = 'Constant';
            break;
        case "L":
            color = 'success';
            title = 'Increase';
            break;
        default:
            color = 'primary';
            title = 'N/A';
    }

    return (
        <Stack direction="row" spacing={1} alignItems="center">
            <Dot color={color}/>
            {
                value
                    ?
                    <Typography>{value} ({title})</Typography>
                    :
                    <Typography>{title}</Typography>
            }
        </Stack>
    );
}

const SoilInteractionsTable: FC<{ analysis: SoilAnalysisType[] }> = ({analysis}) => {
    return (
        <TableContainer sx={{whiteSpace: 'pre-wrap'}} component={Paper}>
            <Table aria-label="soil analysis table" sx={{whiteSpace: 'pre-wrap'}}>
                <TableHead>
                    <TableRow>
                        <TableCell>Interaction</TableCell>
                        <TableCell>Rating</TableCell>
                        <TableCell>Description</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        analysis.map((value) => {
                            return <TableRow>
                                <TableCell><Typography sx={{fontWeight: 'bold'}}>{value.title}</Typography></TableCell>
                                <TableCell><AnalysisResult rating={value.score}/></TableCell>
                                <TableCell>{value.description}</TableCell>
                            </TableRow>
                        })
                    }
                </TableBody>
            </Table>
        </TableContainer>
    );
};


const SoilHealthResults: FC = () => {
    const [report, setReport] = useState<SoilHealthReportType | null>(null)
    const [parcelBoundaryPaths, setParcelBoundaryPaths] = useState<LatLngCoordinatePathType[] | null>(null)
    const [subdivisions, setSubdivisions] = useState<SubDivision[]>([])
    const [subdivisionBoundaryPaths, setSubdivisionBoundaryPaths] = useState<LatLngCoordinatePathType[]>([])
    const [parcelCentroid, setParcelCentroid] = useState<LatLngCoordinateType | null>(null)
    const [boundaryPolygons, setBoundaryPolygons] = useState<PolygonData[]>([])
    const [subDivisionIndex, setSubDivisionIndex] = useState<number | null>(null)
    const {operation} = useOperation()


    useEffect(() => {
        if (operation) {
            const params = {operation_id: operation.id}

            axios.get('/soil-health/soil-health-report/', {params})
                .then(reportInfo => {
                    setReport(reportInfo.data);
                })

            axios.get('/accounts/operation-coords/', {params})
                .then(coordsInfo => {
                    setSubdivisions(coordsInfo.data.subdivisions)
                    setParcelBoundaryPaths(coordsInfo.data.boundaries.map((boundary: RawCoordinatePathType[]) => {
                        return rawToLatLngPath(boundary[0])
                    }))
                    setParcelCentroid(rawTolatLngCoord(coordsInfo.data.centroid))
                    setSubdivisionBoundaryPaths(coordsInfo.data.subdivisions.map((subdivision: SubDivision) => {
                        return rawToLatLngPath(subdivision.boundary)
                    }))
                })
        }
    }, [operation])

    useEffect(() => {
        if (parcelBoundaryPaths && parcelBoundaryPaths.length > 0 && subdivisionBoundaryPaths && subdivisionBoundaryPaths.length > 0) {
            setBoundaryPolygons(
                [
                    ...parcelBoundaryPaths.map((parcelBoundaryPath) => {
                        return {
                            paths: parcelBoundaryPath,
                            strokeColor: '#0000FF',
                            strokeOpacity: 0.8,
                            strokeWeight: 2,
                            fillColor: '#000000',
                            fillOpacity: 0.01,
                            // onClickFn: () => {alert('test')},
                        }
                    }),
                    ...subdivisions.map((subdivision, index) => {
                        return {
                            paths: rawToLatLngPath(subdivision.boundary),
                            strokeColor: '#00FF00',
                            strokeOpacity: 0.8,
                            strokeWeight: 2,
                            fillColor: '#000000',
                            fillOpacity: 0.01,
                            onClickFn: () => setSubDivisionIndex(index),
                            mouseoverFn: () => {},
                            mouseoutFn: () => {},

                        }
                    })
                ]
            )
        }
    }, [parcelBoundaryPaths, subdivisionBoundaryPaths]);


    return <>
        <Grid container spacing={2.5}>
            <Grid item width={'100%'}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link component={RouterLink} to={Paths.RESULTS.INDEX}>
                        Planning
                    </Link>
                    <Typography color="textPrimary">
                        Soil Health
                    </Typography>
                </Breadcrumbs>
            </Grid>
            <DashboardTitle title={"Soil Health"}/>
            <Grid>
            </Grid>

            <Grid item width={'100%'}>
                <Card
                    sx={{
                        width: '100%',
                        padding: '24px',
                        background: '#FFFFFF'
                    }}
                >
                    {(boundaryPolygons && parcelCentroid)
                        ?
                        <Map key={operation ? operation.id : undefined} polygons={boundaryPolygons}
                             centroid={parcelCentroid}></Map>
                        :
                        <></>
                    }
                </Card>
            </Grid>

            {
                subDivisionIndex !== null
                    ?
                    <Grid item width={'100%'}>
                        {/* TODO: Fix map for soil tests and reactivate */}
                        {/*<Grid item width={'50%'}>*/}
                        <Card
                            sx={{
                                width: '100%',
                                background: '#FFFFFF'
                            }}
                        >
                            {
                                report
                                ?
                                <SoilInteractionsTable analysis={report?.soil_test_results[subDivisionIndex].analysis}/>
                                                       :
                                <></>
                            }
                        </Card>
                    </Grid>
                    :
                    <></>
            }
        </Grid>
    </>
};

export default SoilHealthResults;