import Typography from '@mui/material/Typography';
import {
    Breadcrumbs,
    Chip,
    Grid,
    Link,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from '@mui/material';
import {FC} from "react";
import DashboardTitle from "../../components/DashboardTitle";
import {Link as RouterLink} from "react-router-dom";
import Paths from "../../paths";
import {BaseCard, TextBaseCard} from "../../components/BaseCard";
import Markdown from "react-markdown";
import TableCellWithScore from 'components/TableCellWithScore';
import Dot from "../../components/Dot";
import formatCurrencyWithParens from "../../components/formatting/formatCurrencyWithParens";

interface NonDilutiveRowPropsType {
    name: string;
    url?: string | null;
    categories: string;
    maxAmount: number;
    perAcre?: boolean;
    eligibility: number;
    successRate: number;
    overallScore: number;
    previouslyApplied: boolean;
}

interface DebtFundingRowPropsType {
    name: string;
    url?: string | null;
    minRate: number;
    maxRate?: number | null;
    minTermMonths: number;
    maxTermMonths?: number | null;
    overallScore: number;
}


const PreviousAppliedCell: FC<{ previouslyApplied: boolean }> = ({previouslyApplied}) => {

    return <TableCell>
        <Stack direction="row" spacing={1} alignItems="center">
            <Dot color={previouslyApplied ? "error" : "success"}/>
            <Typography>{previouslyApplied ? "Yes" : "No"}</Typography>
        </Stack>
    </TableCell>
}


const DebtFundingRow: FC<DebtFundingRowPropsType> = ({
                                                         name,
                                                         url,
                                                         minRate,
                                                         maxRate = null,
                                                         minTermMonths,
                                                         maxTermMonths = null,
                                                         overallScore
                                                     }) => {
    return <TableRow>
        {url ?
            <TableCell key={2}><Link href={url}><Typography>{name}</Typography></Link></TableCell>
            :
            <TableCell key={2}><Typography>{name}</Typography></TableCell>
        }
        <TableCell key={2}><Typography>{minRate}%{maxRate ? ` - ${maxRate}%` : ""}</Typography></TableCell>
        <TableCell
            key={2}><Typography>{minTermMonths}{maxTermMonths ? ` - ${maxTermMonths}` : ""} months</Typography></TableCell>
        <TableCellWithScore score={overallScore}/>
    </TableRow>
}

const NonDilutiveRow: FC<NonDilutiveRowPropsType> = ({
                                                         name,
                                                         url = null,
                                                         categories,
                                                         maxAmount,
                                                         perAcre = false,
                                                         eligibility,
                                                         successRate,
                                                         overallScore,
                                                         previouslyApplied,
                                                     }) => {
    return <TableRow>
        {url ?
            <TableCell key={2}><Link href={url}><Typography>{name}</Typography></Link></TableCell>
            :
            <TableCell key={2}><Typography>{name}</Typography></TableCell>
        }
        <TableCell><Chip label={categories} color={"primary"}/></TableCell>
        <TableCell sx={{textAlign: 'right'}}
            key={2}><Typography>{formatCurrencyWithParens(maxAmount, false)}{perAcre ? "/acre" : ""}</Typography></TableCell>
        <TableCellWithScore score={eligibility}/>
        <TableCellWithScore score={successRate}/>
        <TableCell><PreviousAppliedCell previouslyApplied={previouslyApplied}/></TableCell>
        <TableCellWithScore score={overallScore}/>
    </TableRow>
}

const Funding: FC = () => {

    return (
        <>
            <Grid container spacing={2.5}>
                <Grid item width={'100%'}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link component={RouterLink} to={Paths.FULL_SURVEY.INDEX}>
                            Full Survey Report
                        </Link>
                        <Typography color="textPrimary">
                            Funding
                        </Typography>
                    </Breadcrumbs>
                </Grid>
                <DashboardTitle title={"Funding"}/>
                <Grid item width={'100%'}>
                    <TextBaseCard title={"Summary"}>
                        <Markdown>
                            {"The Bennington farm and the operation on it are eligible for a number of funding oportunities, both debt and grant funding. Infrastructure costs may be subsidised with grant funding from EQIP, though success rates are low. Cover Crop incentive payments from Farmers for Soil Health also present an opportunity, though are only eligible within corn/soy rotations. \n\n There are some debt financing options that focus specifically on regenerative/organic operations. These offer rates only slightly above bank and Farm Credit financing options."}
                        </Markdown>
                    </TextBaseCard>
                </Grid>
                <Grid item width={'100%'}>
                    <BaseCard title={"Grant Funding"}>
                        <TableContainer
                            sx={{
                                width: '100%',
                                overflowX: 'auto',
                                position: 'relative',
                                display: 'block',
                                maxWidth: '100%',
                            }}
                        >
                            <Table aria-labelledby="tableTitle" sx={{
                                maxWidth: '100%',
                                tableLayout: "auto",
                                // width: "auto"
                            }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell key={1}>Name</TableCell>
                                        <TableCell key={3}>Category</TableCell>
                                        <TableCell key={3}>Max Amount</TableCell>
                                        <TableCell key={4}>Eligibility</TableCell>
                                        <TableCell key={5}>Success Rates</TableCell>
                                        <TableCell key={6}>Previously Applied</TableCell>
                                        <TableCell key={7}>Overall Score</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <NonDilutiveRow name={"EQIP"} maxAmount={450000} eligibility={90} successRate={18}
                                                    overallScore={70} previouslyApplied={false} categories={"General"}/>
                                    <NonDilutiveRow name={"SARE Farmer Rancher Grant"}
                                                    url={"https://northcentral.sare.org/grants/apply-for-a-grant/farmer-rancher-grant/#recent-projects"}
                                                    maxAmount={15000} eligibility={100} successRate={30}
                                                    overallScore={70} previouslyApplied={false} categories={"General"}/>
                                    <NonDilutiveRow name={"Young Farmer Grant Program"}
                                                    url={"https://www.youngfarmers.org/youngfarmergrants/"}
                                                    maxAmount={5000} eligibility={100} successRate={30}
                                                    overallScore={65} previouslyApplied={true}
                                                    categories={"Young Farmers"}/>
                                    <NonDilutiveRow name={"Farmers for Soil Health"}
                                                    url={"https://farmersforsoilhealth.com/enrollment/"}
                                                    maxAmount={50} eligibility={20} successRate={50} perAcre={true}
                                                    overallScore={30} previouslyApplied={true}
                                                    categories={"Cover Crops"}/>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </BaseCard>
                </Grid>
                <Grid item width={'100%'}>
                    <BaseCard title={"Debt and Equity Funding"}>
                        <TableContainer
                            sx={{
                                width: '100%',
                                overflowX: 'auto',
                                position: 'relative',
                                display: 'block',
                                maxWidth: '100%',
                            }}
                        >
                            <Table aria-labelledby="tableTitle" sx={{
                                maxWidth: '100%',
                                tableLayout: "auto",
                                // width: "auto"
                            }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell key={1}>Name</TableCell>
                                        <TableCell key={5}>Rates</TableCell>
                                        <TableCell key={6}>Previously Applied</TableCell>
                                        <TableCell key={7}>Overall Score</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <DebtFundingRow name={"Steward"} url={'https://gosteward.com/'} minRate={5}
                                                    maxRate={9} overallScore={50} minTermMonths={48}
                                                    maxTermMonths={72}/>
                                    <DebtFundingRow name={"MAD! Capital"} url={"https://madcapital.com/"} minRate={6}
                                                    maxRate={9} overallScore={40} minTermMonths={12}
                                                    maxTermMonths={120}/>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </BaseCard>
                </Grid>
            </Grid>
        </>
    )
        ;
};

export default Funding;