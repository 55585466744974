import Typography from '@mui/material/Typography';
import {Breadcrumbs, Card, Grid, Link} from '@mui/material';
import {FC, useEffect, useState} from "react";
import DashboardTitle from "../../components/DashboardTitle";
import {Link as RouterLink} from "react-router-dom";
import Paths from "../../paths";
import {TextBaseCard} from "../../components/BaseCard";
import Markdown from "react-markdown";
import axios from "../../services/APIService";
import {useOperation} from "../../contexts/OperationContext";
import {FinancialDataTS} from "../../types/financialData/PLData";
import PLTable from "../../components/financialData/PLTable";


// interface FinancialDetailItemType {
//     total: number
//
//     [title: string]: number
// }

// interface FinancialDetailCategoryType {
//     [category: string]: FinancialDetailItemType
// }


// interface FinancialDetailsType {
//     net_income: number,
//     costs: {
//         total: number,
//         categories: FinancialDetailCategoryType
//     },
//     revenues: {
//         total: number,
//         categories: FinancialDetailCategoryType
//     },
// }


// const FinancialDetailsTable: FC<{ financialDetails: FinancialDetailsType | null }> = ({financialDetails}) => {
//     if (!financialDetails) {
//         return <></>
//     }
//     return <TableContainer
//         sx={{
//             width: '100%',
//             overflowX: 'auto',
//             position: 'relative',
//             display: 'block',
//             maxWidth: '100%',
//         }}
//     >
//         <Table aria-labelledby="tableTitle" sx={{
//             maxWidth: '100%',
//             tableLayout: "auto",
//             // width: "auto"
//         }}>
//             <TableHead>
//                 <TableRow>
//                     <TableCell key={1}>{""}</TableCell>
//                     <TableCell key={2} sx={{textAlign: "right"}}><Typography fontWeight={"bold"}>2023</Typography></TableCell>
//                 </TableRow>
//             </TableHead>
//             <TableBody>
//                 <TableRow key={1}>
//                     <TableCell key={1}><Typography fontWeight={800}>Net Income</Typography></TableCell>
//                     <TableCell key={2}
//                         sx={{textAlign: "right"}}><Typography
//                         fontWeight={800}>{formatCurrencyWithParens(financialDetails.net_income)}</Typography></TableCell>
//                 </TableRow>
//                 <TableRow key={2}>
//                     <TableCell key={1}><Typography sx={{paddingLeft: '8px'}} fontWeight={600}>Revenues</Typography></TableCell>
//                     <TableCell key={2} sx={{textAlign: "right"}}><Typography fontWeight={600}
//                                                                      sx={{paddingRight: '8px'}}>{formatCurrencyWithParens(financialDetails.revenues.total)}</Typography></TableCell>
//                 </TableRow>
//                 <FinancialDetailsTableCategoryRows categories={financialDetails.revenues.categories}/>
//                 <TableRow key={4}>
//                     <TableCell key={1}><Typography sx={{paddingLeft: '8px'}} fontWeight={600}>Costs</Typography></TableCell>
//                     <TableCell key={2} sx={{textAlign: "right"}}><Typography fontWeight={600}
//                                                                      sx={{paddingRight: '8px'}}>{formatCurrencyWithParens(financialDetails.costs.total)}</Typography></TableCell>
//                 </TableRow>
//                 <FinancialDetailsTableCategoryRows categories={financialDetails.costs.categories}/>
//             </TableBody>
//         </Table>
//     </TableContainer>
// }

// const FinancialDetailsTableCategoryRows: FC<{ categories: FinancialDetailCategoryType }> = ({categories}) => {
//     const rows = Object.entries(categories).map(([key, value], index) => {
//         return <>
//             <TableRow key={index}>
//                 <TableCell key={1}><Typography sx={{paddingLeft: '16px'}}>{key}</Typography></TableCell>
//                 <TableCell sx={{textAlign: "right"}} key={2}><Typography
//                     sx={{paddingRight: '16px'}}>{formatCurrencyWithParens(value.total)}</Typography></TableCell>
//             </TableRow>
//             <FinancialDetailsTableDetailRows detailsData={value}/>
//         </>
//     })
//     return <>
//         {rows}
//     </>
// }
//
// const FinancialDetailsTableDetailRows: FC<{ detailsData: FinancialDetailItemType }> = ({detailsData}) => {
//     const rows = Object.entries(detailsData).map(([key, value], index) => {
//         if (key === 'total') {
//             return <></>
//         }
//         return <TableRow key={index}>
//             <TableCell key={1}><Typography sx={{paddingLeft: '24px'}}>{key}</Typography></TableCell>
//             <TableCell sx={{textAlign: "right"}} key={2}><Typography
//                 sx={{paddingRight: '24px'}}>{formatCurrencyWithParens(value)}</Typography></TableCell>
//         </TableRow>
//     })
//     return <>
//         {rows}
//     </>
// }

const financialData: FinancialDataTS = {
    "year": ['2023'],
    "net_income": [-12170.88],
    "costs": {
        "totals": [-20715.48],
        "categories": [
            {
                "name": "Per Cow",
                "totals": [-10365.48],
                "subcategories": [
                    {
                        "name": "Harvested Forage (Hay)",
                        "totals": [-4860],
                    },
                    {
                        "name": "Grain/Protein Supplements",
                        "totals": [0],
                    },
                    {
                        "name": "Mineral",
                        "totals": [-102.6],
                    }, {
                        "name": "Other Feed",
                        "totals": [0],
                    }, {
                        "name": "Replacement Females",
                        "totals": [-5402.88],
                    }
                ]
            },
            {
                "name": "Per Operation / Herd",
                "totals": [-7200],
                "subcategories": [
                    {
                        "name": "Annual Bull Charge or A.I.",
                        "totals": [-3000],
                    },
                    {
                        "name": "Other Livestock Breeding/Marketing",
                        "totals": [0],
                    },
                    {
                        "name": "Utilities",
                        "totals": [-300],
                    },
                    {
                        "name": "Gas, Fuel, Oil",
                        "totals": [-300],
                    },
                    {
                        "name": "Machinery, Facility/Equipment Repairs",
                        "totals": [-900],
                    },
                    {
                        "name": "Cash Interest Paid",
                        "totals": [-400],
                    },
                    {
                        "name": "Other variable costs",
                        "totals": [-200],
                    },
                    {
                        "name": "Labor (Hired & Operator)",
                        "totals": [-2100],
                    },
                ]
            },
            {
                "name": "Per Acre",
                "totals": [-3150],
                "subcategories": [
                    {
                        "name": "Pasture Seeding",
                        "totals": [-3150],
                    },
                ]
            }
        ]
    },
    "revenues": {
        "totals": [8544.6],
        "categories": [
            {
                "name": "Animal Sales",
                "totals": [8544.6],
                "subcategories": [
                    {
                        "name": "Weaned Calf Sales",
                        "totals": [5232.6],
                    },
                    {
                        "name": "Cull Cows Sales",
                        "totals": [3312],
                    },
                ]
            }
        ]
    }
}

const Enterprises: FC = () => {
    const [summary, setSummary] = useState<string>("")
    // const [financialDetails, setFinancialDetails] = useState<FinancialDetailsType | null>(null)
    const {operation} = useOperation()

    useEffect(() => {
        if (operation) {
            const params = {operation_id: operation.id}
            axios.get('/finances/financial-summary', {params})
                .then(financialInfo => {
                    // setFinancialDetails(financialInfo.data.details)
                    setSummary(financialInfo.data.summary)
                })
        }
    }, [operation])

    return (
        <>
            <Grid container spacing={2.5}>
                <Grid item width={'100%'}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link component={RouterLink} to={Paths.FULL_SURVEY.INDEX}>
                            Full Survey Report
                        </Link>
                        <Typography color="textPrimary">
                            Finances
                        </Typography>
                    </Breadcrumbs>
                </Grid>
                <DashboardTitle title={"Finances"}/>
                <Grid item width={'100%'}>
                    <TextBaseCard title={"Summary"}>
                        <Markdown>
                            {summary}
                        </Markdown>
                    </TextBaseCard>
                </Grid>
                <Grid item width={'100%'}>
                    <Card>
                        {/*<FinancialDetailsTable financialDetails={financialDetails}/>*/}
                        <PLTable financialData={financialData} valueCellCallback={() => {}}/>
                    </Card>
                </Grid>
            </Grid>
        </>
    )
        ;
};

export default Enterprises;