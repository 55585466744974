import Typography from '@mui/material/Typography';
import {Breadcrumbs, Grid, Link} from '@mui/material';
import {FC, useEffect} from "react";
import DashboardTitle from "../../../components/DashboardTitle";
import {Link as RouterLink} from "react-router-dom";
import Paths from "../../../paths";
import {BaseCard} from "../../../components/BaseCard";
import {useOperation} from "../../../contexts/OperationContext";
import axios from "../../../services/APIService";
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent, {timelineOppositeContentClasses,} from '@mui/lab/TimelineOppositeContent';
import Markdown from "react-markdown";

const PlanningLongTermTimelineItem: FC<{ year: string, headline: string, content: string }> = ({
                                                                                                   year,
                                                                                                   headline,
                                                                                                   content
                                                                                               }) => {
    const headlineSplit = headline.split(":")

    return <TimelineItem>
        <TimelineOppositeContent color="textSecondary">
            {year}
        </TimelineOppositeContent>
        <TimelineSeparator>
            <TimelineDot color="primary"/>
            <TimelineConnector/>
        </TimelineSeparator>
        <TimelineContent>
            <Typography variant={"h5"} sx={{fontStyle: 'italic'}} component={"span"}>
                {headlineSplit[0]}:
            </Typography>
            <Typography variant={"h5"} sx={{fontWeight: '500'}} component={"span"}>
                {headlineSplit[1]}
            </Typography>
            <Markdown>
                {content}
            </Markdown>
        </TimelineContent>
    </TimelineItem>
}


const PlanningLongTermTimeline = () => {
    return (
        <Timeline
            sx={{
                [`& .${timelineOppositeContentClasses.root}`]: {
                    flex: 0.2,
                },
            }}
        >
            <PlanningLongTermTimelineItem
                year={'2024'}
                headline={'Decrease costs and establish new system: Reduce hay costs and prepare for improved grazing and pasture'}
                content={`**Pasture Seeding:** Interseed cover crops to enable grazing later into winter, reducing hay needs from 4 to 3 tons per AU
                       
**Neutralize Endophyte Impact:** Diversify pasture to decrease impact of endophyte infection on calving and weaning rates in summer months

**Daily Moves:** Start moving cattle daily to keep fertility in pasture, reducing need for fertilizer

**Improve Water Infrastructure**: Add piping to enable daily moves`}
            />
            <PlanningLongTermTimelineItem
                year={'2025'}
                headline={'Reach Break-even: Weaning rates rate increasing calf sale revenues, increasing herd size, while further decreasing to hay costs.'}
                content={`**Increase Herd Size:** Add 5 cows (18 to 23) due to increased carrying capacity from more productive pasture with cover crops and use of annuals
                       
**Increased Weaning Rates:** Less summer grazing in infected fescue increases cow calving rates 

**Lower hay costs:** Stockpiling fescue will provide feed further through winter, lowering reliance on hay  

**Annual Pasture:** Continue with summer and winter annuals in preparation for planting warm-season grasses`}
            />
            <PlanningLongTermTimelineItem
                year={'2026'}
                headline={'Achieve profitability: Increase weaning rate to 90%, calf sale weights from 600 to 650lbs, and herd size by 22% while further decrease hay costs'}
                content={`**Increase Herd Size:** Add 5 cows (23 to 28) due to increased productivity
                
**Increased Sale Weights:** More nutritious pasture will allow more gan per animal and therefore higher sale weights (from 600lbs to 650lbs), increasing income.

**Maximize Weaning Rates:** Interseeded legumes will counteract fescue issues, removing all pasture-based limitations to calving productivity, taking rates to 90%+.

**Decrease Hay Costs:** Further decreases in hay needs from 2 to 1 tons/AU will continue cost decreases.
                `}
            />
            <PlanningLongTermTimelineItem
                year={'2027'}
                headline={'Grow Profits: Increased pasture productivity allows larger herd and higher sale weights with lower hay needs'}
                content={`**Increase Herd Size:** Increase from 23 to 28
                `}
            />
        </Timeline>
    );
}

// const CalvingRateChip = () => {
//     return <Chip size={"small"} sx={{backgroundColor: "#f7e199", marginRight: "4px", marginBottom: "4px"}} label="Calving Rates"/>
// }
//
// const HayCostChip = () => {
//     return <Chip size={"small"} sx={{backgroundColor: "#baedad", marginRight: "4px", marginBottom: "4px"}} label="Hay Costs"/>
// }
//
// const PastureProductivityChip = () => {
//     return <Chip size={"small"} sx={{backgroundColor: "#b9cff0", marginRight: "4px", marginBottom: "4px"}} label="Pasture Productivity"/>
// }
//

const PlanningOverview = () => {
    const {operation} = useOperation()

    useEffect(() => {
        if (operation) {
            const params = {operation_id: operation.id}
            axios.get('/planning/operations', {params})
                .then(response => {
                })
        }
    }, [operation]);

    return (
        <>
            <Grid container spacing={2.5}>
                <Grid item width={'100%'}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link component={RouterLink} to={Paths.PLANNING.INDEX}>
                            Planning
                        </Link>
                        <Typography color="textPrimary">
                            Strategy
                        </Typography>
                    </Breadcrumbs>
                </Grid>
                <DashboardTitle title={"Strategy"}/>
                <Grid item width={'100%'}>
   {/*                 <Timeline*/}
   {/*                     sx={{*/}
   {/*                         [`& .${timelineItemClasses.root}:before`]: {*/}
   {/*                             flex: 0,*/}
   {/*                             padding: 0,*/}
   {/*                         },*/}
   {/*                     }}*/}
   {/*                 >*/}
   {/*                     <TimelineItem>*/}
   {/*                         <TimelineSeparator>*/}
   {/*                             <TimelineDot variant="outlined" color="primary"/>*/}
   {/*                             <TimelineConnector/>*/}
   {/*                         </TimelineSeparator>*/}
   {/*                         <TimelineContent>*/}
   {/*                             <TextBaseCard title={"Opportunities"}>*/}
   {/*                                 <Grid container width={"100%"}>*/}
   {/*                                     <Grid item width={"33.3%"} sx={{paddingX: "6px"}}>*/}
   {/*                                         <CalvingRateChip/>*/}
   {/*                                         <Typography variant={"h5"} sx={{marginTop: "4px"}}>Double revenue/cow</Typography>*/}
   {/*                                         <Typography>{'-'} Current calving rate is ~45%</Typography>*/}
   {/*                                         <Typography>{'-'} Below potential (over 90%)</Typography>*/}
   {/*                                     </Grid>*/}
   {/*                                     <Grid item width={"33.3%"} sx={{paddingX: "6px"}}>*/}
   {/*                                         <HayCostChip/>*/}
   {/*                                         <Typography variant={"h5"} sx={{marginTop: "4px"}}>Save over $300 hay*/}
   {/*                                             costs/cow</Typography>*/}
   {/*                                         <Typography>{'-'} Currently feeding 4 tons/cow</Typography>*/}
   {/*                                         <Typography>{'-'} Only 5 months/year productive pasture grazing</Typography>*/}
   {/*                                         <Typography>{'-'} Feed less than 0.5 tons/cow at full potential</Typography>*/}
   {/*                                     </Grid>*/}
   {/*                                     <Grid item width={"33.3%"} sx={{paddingX: "6px"}}>*/}
   {/*                                         <PastureProductivityChip/>*/}
   {/*                                         <Typography variant={"h5"} sx={{marginTop: "4px"}}>Increase cows per acre by*/}
   {/*                                             4x</Typography>*/}
   {/*                                         <Typography>{'-'} Monoculture grass limits months of grazing</Typography>*/}
   {/*                                         <Typography>{'-'} Lack of diversity limits gain per acre</Typography>*/}
   {/*                                     </Grid>*/}
   {/*                                 </Grid>*/}
   {/*                             </TextBaseCard>*/}
   {/*                         </TimelineContent>*/}
   {/*                     </TimelineItem>*/}
   {/*                     <TimelineItem>*/}
   {/*                         <TimelineSeparator>*/}
   {/*                             <TimelineDot variant="outlined" color="primary"/>*/}
   {/*                             <TimelineConnector/>*/}
   {/*                         </TimelineSeparator>*/}
   {/*                         <TimelineContent>*/}
   {/*                             /!*</Grid>*!/*/}
   {/*                             /!*<Grid item width={'100%'}>*!/*/}
   {/*                             <TextBaseCard title={"Operational Changes"}>*/}
   {/*                                 /!*<Markdown>{`Enhancing fertility and forage management will significantly boost pasture productivity, calving rates, and herd gains, leading to increased farm profitability. By ensuring even nutrient distribution and leveraging soil biology, we reduce the need for synthetic fertilizers and lower operational costs. Seasonal forage rotation and strategic grazing practices extend forage availability, optimizing livestock health and minimizing feed expenses.`}</Markdown>*!/*/}
   {/*                                 /!*<Markdown>*!/*/}
   {/*                                 /!*    {`## Fertility Management`}*!/*/}
   {/*                                 /!*</Markdown>*!/*/}
   {/*                                 <Grid container width={"100%"} sx={{marginBottom: "12px"}}>*/}
   {/*                                     <Grid item width={"33.3%"}>*/}
   {/*                                         <Typography variant={"h5"} sx={{marginBottom: "4px"}}>Increase Field*/}
   {/*                                             Fertility</Typography>*/}
   {/*                                         <Typography>{'-'} Boost with initial NPK fertilizer</Typography>*/}
   {/*                                         <Typography>{'-'} Use legumes to increase nitrogen fixation</Typography>*/}
   {/*                                         <Box sx={{marginTop: "6px"}}>*/}
   {/*                                             <HayCostChip/>*/}
   {/*                                             <PastureProductivityChip/>*/}
   {/*                                         </Box>*/}
   {/*                                         /!*                             <Markdown>{*!/*/}
   {/*                                         /!*                                 `#### Increase Field Fertility*!/*/}
   {/*                                         /!*- Boost with initial NPK fertilizer.*!/*/}
   {/*                                         /!*- Maintain fertility through management.`}*!/*/}
   {/*                                         /!*                             </Markdown>*!/*/}

   {/*                                     </Grid>*/}

   {/*                                     <Grid item width={"33.3%"}>*/}
   {/*                                         <Typography variant={"h5"} sx={{marginBottom: "4px"}}>Limit Fertility*/}
   {/*                                             Removal</Typography>*/}
   {/*                                         <Typography>{'-'} Use small paddocks moved daily</Typography>*/}
   {/*                                         <Typography>{'-'} Use batt-latches to minimize labor needs</Typography>*/}
   {/*                                         <Box sx={{marginTop: "6px"}}>*/}
   {/*                                             <PastureProductivityChip/>*/}
   {/*                                         </Box>*/}
   {/*                                     </Grid>*/}

   {/*                                     <Grid item width={"33.3%"}>*/}
   {/*                                         <Typography variant={"h5"} sx={{marginBottom: "4px"}}>Increase non-N Microbe Population</Typography>*/}
   {/*                                         <Typography>{'-'} Apply PK-focused biologicals</Typography>*/}
   {/*                                         <Typography>{'-'} Stop tillage</Typography>*/}
   {/*                                         <Box sx={{marginTop: "6px"}}>*/}
   {/*                                             <HayCostChip/>*/}
   {/*                                             <PastureProductivityChip/>*/}
   {/*                                         </Box>*/}
   {/*/!*                                         <Markdown>{*!/*/}
   {/*/!*                                             ` #### Enhance Soil Biology*!/*/}
   {/*/!*- Apply soil microbes to replenish nutrients.*!/*/}
   {/*/!*- Inter-seed legumes and forbs to improve soil health.*!/*/}
   {/*/!*- Maintain soil microbes by keeping roots in the ground and preserving soil structure.`}*!/*/}
   {/*/!*                                         </Markdown>*!/*/}

   {/*                                     </Grid>*/}
   {/*                                 </Grid>*/}

   {/*                                 /!*<Markdown>*!/*/}
   {/*                                 /!*    {`## Forage Management`}*!/*/}
   {/*                                 /!*</Markdown>*!/*/}
   {/*                                 <Grid container width={"100%"}>*/}
   {/*                                     <Grid item width={"33.3%"}>*/}
   {/*                                         <Typography variant={"h5"} sx={{marginBottom: "4px"}}>Diversify Forage</Typography>*/}
   {/*                                         <Typography>{'-'} Introduce legumes and forbs</Typography>*/}
   {/*                                         <Typography>{'-'} Ensure diverse species can reseed</Typography>*/}
   {/*                                         <Box sx={{marginTop: "6px"}}>*/}
   {/*                                             <CalvingRateChip/>*/}
   {/*                                             <HayCostChip/>*/}
   {/*                                             <PastureProductivityChip/>*/}
   {/*                                         </Box>*/}
   {/*/!*                                         <Markdown>{*!/*/}
   {/*/!*                                             `#### Diversify Forage*!/*/}
   {/*/!*- Introduce diverse legumes pasture to increase nitrogen fixation and forage yield*!/*/}
   {/*/!*- Negate impact of endophyte infection on cows to increase calving and weaning rates*!/*/}
   {/*/!*- Add resilience to different weather patterns`}*!/*/}
   {/*/!*                                         </Markdown>*!/*/}

   {/*                                     </Grid>*/}

   {/*                                     <Grid item width={"33.3%"}>*/}
   {/*                                         <Typography variant={"h5"} sx={{marginBottom: "4px"}}>Extend Forage Window</Typography>*/}
   {/*                                         <Typography>{'-'} Replant some pasture to native grasses</Typography>*/}
   {/*                                         <Typography>{'-'} Use non-endophyte fescue for winter grazing</Typography>*/}
   {/*                                         <Box sx={{marginTop: "6px"}}>*/}
   {/*                                             <HayCostChip/>*/}
   {/*                                         </Box>*/}
   {/*/!*                                         <Markdown>{*!/*/}
   {/*/!*                                             `#### Extend Forage Availability*!/*/}
   {/*/!*- Rotate livestock through different pastures year-round.*!/*/}
   {/*/!*- Use diverse range of pastures to provide forage in all seasons*!/*/}
   {/*/!*- Ensure there is productive pasture at all time*!/*/}
   {/*/!*  `}*!/*/}
   {/*/!*                                         </Markdown>*!/*/}

   {/*                                     </Grid>*/}


   {/*                                     <Grid item width={"33.3%"}>*/}
   {/*                                          <Typography variant={"h5"} sx={{marginBottom: "4px"}}>Move Calving Season</Typography>*/}
   {/*                                         <Typography>{'-'} Avoid calving after summer grazing infected fescue</Typography>*/}
   {/*                                         <Typography>{'-'} Crossbreed to align genetics with gestation season</Typography>*/}
   {/*                                         <Box sx={{marginTop: "6px"}}>*/}
   {/*                                             <CalvingRateChip/>*/}
   {/*                                             <HayCostChip/>*/}
   {/*                                         </Box>*/}
   {/*/!*                                         <Markdown>{*!/*/}
   {/*/!*                                             `  #### Winter Grazing*!/*/}
   {/*/!*- Use pasture like fescue into winter to reduce hay costs.*!/*/}
   {/*/!*- Develop water infrastructure for freezing temperatures.`}*!/*/}
   {/*/!*                                         </Markdown>*!/*/}

   {/*                                     </Grid>*/}
   {/*                                 </Grid>*/}
   {/*                             </TextBaseCard>*/}
   {/*                         </TimelineContent>*/}
   {/*                     </TimelineItem>*/}
   {/*                     <TimelineItem>*/}
   {/*                         <TimelineSeparator>*/}
   {/*                             <TimelineDot variant="outlined" color="primary"/>*/}
   {/*                             <TimelineConnector/>*/}
   {/*                         </TimelineSeparator>*/}
   {/*                         <TimelineContent>*/}
   {/*                             /!*</Grid>*!/*/}
   {/*                             /!*<Grid item width={'100%'}>*!/*/}
                                <BaseCard title={"Timeline"}>
                                    <PlanningLongTermTimeline/>
                                </BaseCard>
                    {/*        </TimelineContent>*/}
                    {/*    </TimelineItem>*/}
                    {/*</Timeline>*/}
                </Grid>
            </Grid>
        </>
    )
}

export default PlanningOverview