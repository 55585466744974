// material-ui
import {Theme} from '@mui/material/styles';

// ==============================|| OVERRIDES - LIST ITEM ICON ||============================== //

export default function ListItemButton(theme: Theme) {
    return {
        MuiListItemButton: {
            styleOverrides: {
                root: {
                    '&.Mui-selected': {
                        background: theme.palette.primary.lighter,
                        color: theme.palette.primary.darker,
                        '& .MuiListItemIcon-root': {
                            background: theme.palette.primary.lighter,
                        }
                    },
                    '&:hover': {
                        background: theme.palette.primary.lighter,
                        color: theme.palette.primary.darker,
                    }
                }
            }
        }
    };
}
