import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import {useTheme} from '@mui/material/styles';
import {DRAWER_WIDTH} from 'config';
import {Menu, MenuItem, Stack} from "@mui/material";
import {AccountCircle} from "@mui/icons-material";
import {useState} from "react";
import Box from "@mui/material/Box";
import {useNavigate} from "react-router-dom";
import Typography from "@mui/material/Typography";
import {useCustomer} from "../contexts/CustomerContext";
import Button from "@mui/material/Button";
import {useOperation} from "../contexts/OperationContext";
import {Operation} from "../types/operation";
import {useAuth} from "../auth/AuthContext";


export default function Header() {
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const navigate = useNavigate();
    const {logout} = useAuth();
    const {customer, fullName} = useCustomer();
    const {operation, saveOperation} = useOperation();

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        // Perform logout operations here
        console.log('Logging out');
        logout();
        console.log('...and sending home')
        navigate('/')
    };

    const setOperationClick = (operation: Operation) => {
        return (event: React.MouseEvent<HTMLLIElement>) => {
            event.preventDefault()
            saveOperation(operation)
        }

    }

    const OperationList = () => {
        return <>
            {
                customer?.operations.map((operationOption) => {
                    return <MenuItem key={operationOption.id} onClick={setOperationClick(operationOption)}
                                     selected={operationOption === operation}>{operationOption.name}</MenuItem>
                })
            }
        </>
    }

    return (
        // <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <AppBar
            sx={{
                bgcolor: 'grey.0',
                borderBottomColor: 'grey.200',
                borderBottomWidth: '1px',
                borderBottomStyle: 'solid',
                marginLeft: {xs: 0, sm: DRAWER_WIDTH},
                width: {xs: '100%', sm: `calc(100% - ${DRAWER_WIDTH}px)`},
                color: theme.palette.text.primary,
                boxShadow: 'none'
            }}
        >
            {/*<Toolbar>*/}
            {/*</Toolbar>*/}
            <Toolbar>
                <Box sx={{flexGrow: 1}}/>
                <div>
                    {
                        customer ?
                            <>
                                <Button
                                    size="large"
                                    aria-label="account of current user"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={handleMenu}
                                    color="inherit"
                                >
                                    <Stack direction="row" spacing={1} alignItems="center">
                                        <Typography variant={"h4"}>{fullName()}</Typography>
                                        {/*<Icon>*/}
                                        <AccountCircle/>
                                        {/*</Icon>*/}
                                    </Stack>
                                </Button>
                                <Menu
                                    id="menu-appbar"
                                    anchorEl={anchorEl}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    open={Boolean(anchorEl)}
                                    onClose={handleClose}
                                >
                                    <OperationList/>
                                    <MenuItem onClick={handleLogout}>Logout</MenuItem>
                                </Menu>
                            </>
                            :
                            <></>
                    }
                </div>
            </Toolbar>
        </AppBar>
    );
}