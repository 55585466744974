import React, {createContext, ReactNode, useContext, useEffect, useState} from 'react';
import {Operation} from "../types/operation";

interface OperationProviderProps {
    children: ReactNode;
}

interface OperationContextType {
    operation: Operation | null;
    saveOperation: (operation: Operation | null) => void;
}


const OperationContext = createContext<OperationContextType | undefined>(undefined);

export const OperationProvider: React.FC<OperationProviderProps> = ({children}) => {

    const [operation, setOperation] = useState<Operation | null>(null);

    const saveOperation = (newOperation: Operation | null) => {
        setOperation(newOperation);
        // Note: these need to be cleared in logout flow
        if (newOperation) {
            localStorage.setItem('operationId', newOperation.id.toString());
            localStorage.setItem('operationName', newOperation.name);
            localStorage.setItem('hubName', newOperation.hub);
        } else {
            localStorage.removeItem('operationId');
            localStorage.removeItem('operationName');
            localStorage.removeItem('hubName');
        }
    }


    useEffect(() => {
        const id = localStorage.getItem('operationId');
        const name = localStorage.getItem('operationName')
        const hub = localStorage.getItem('hubName')

        if (id && name && hub) {
            setOperation({
                id: Number(id),
                name,
                hub,
            })
        }
    }, []);

    return (
        <OperationContext.Provider value={{operation, saveOperation}}>
            {children}
        </OperationContext.Provider>
    );
};

export const useOperation = () => {
    const context = useContext(OperationContext);
    if (context === undefined) {
        throw new Error('useOperation must be used within a OperationProvider');
    }
    return context;
}