import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL
});

instance.interceptors.request.use(
  (config) => {
    // Attempt to retrieve the token from localStorage
    const token = localStorage.getItem('authToken');

    // If the token exists, include it in the Authorization header
    if (token !== undefined && token !== null) {
      config.headers = config.headers || {};
      config.headers.Authorization = `Token ${token}`;
    }

    return config;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  }
);

export default instance;