import Typography from '@mui/material/Typography';
import {
    Breadcrumbs,
    Card,
    Collapse,
    Grid,
    IconButton,
    Link,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from '@mui/material';
import {FC, useEffect, useState} from "react";
import DashboardTitle from "../../components/DashboardTitle";
import {Link as RouterLink} from "react-router-dom";
import Paths from "../../paths";
import {TextBaseCard} from "../../components/BaseCard";
import Markdown from "react-markdown";
import {Star} from "@mui/icons-material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Box from "@mui/material/Box";
import {LatLngCoordinatePathType, LatLngCoordinateType, PolygonData, RawCoordinatePathType} from "../../types/geo";
import {useOperation} from "../../contexts/OperationContext";
import axios from "../../services/APIService";
import {rawTolatLngCoord, rawToLatLngPath} from "../../utils/geo";
import Map from "../../components/Map";


interface InfrastructureItemType {
    name: string;
    description: string;
    boundaries: RawCoordinatePathType[];
    type: 'FENCE' | 'SHADE_TREES' | 'STANDING_WATER';
}

interface InfrastructureDescriptionType {
    summary: string;
    details: string;
}

const InfrastructureTypeMapConfig = {
    FENCE: {
        strokeColor: '#FF0000',
        strokeOpacity: 0.4,
        strokeWeight: 0,
        fillColor: '#FF0000',
        fillOpacity: 0.4,
    },
    SHADE_TREES: {
        strokeColor: '#00FF00',
        strokeOpacity: 0.4,
        strokeWeight: 0,
        fillColor: '#00FF00',
        fillOpacity: 0.4,
    },
    STANDING_WATER: {
        strokeColor: '#0000FF',
        strokeOpacity: 0.4,
        strokeWeight: 0,
        fillColor: '#0000FF',
        fillOpacity: 0.4,
    },
}

const Row1: FC = () => {
    const [open, setOpen] = useState(false);


    return <>
        <TableRow onClick={() => setOpen(!open)}>
            <TableCell key={0}>
                <IconButton
                    aria-label="expand row"
                    size="small"
                >
                    {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                </IconButton>
            </TableCell>
            <TableCell key={1}><Typography sx={{fontWeight: 'bold'}}>Adaptive Multi-Paddock (AMP)
                Grazing</Typography></TableCell>
            <TableCell key={3}>
                <Star/>
                <Star/>
                <Star/>
            </TableCell>
        </TableRow>
        <TableRow style={{width: "100%"}}>
            <TableCell style={{paddingBottom: 0, paddingTop: 0, width: "100%", overflow: 'hidden'}} colSpan={4}>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <Box>
                        <Markdown>
                            {`
#### **Current Infrastructure**
- **Water Access**: The installation of water lines along the farm's east border establishes a basic water supply. However, AMP grazing necessitates accessible water in all paddocks to support the frequent relocation of livestock. The existing water system may require expansions or modifications.
- **Fencing**: While high-quality perimeter fencing exists, AMP grazing demands extensive subdivision of pastures into smaller paddocks. This requires temporary or permanent fencing to manage grazing intensity and facilitate pasture recovery. The current fencing infrastructure likely needs substantial enhancements.
- **Shade**: Existing shade from trees near farm buildings provides limited relief for livestock but may fall short of AMP grazing needs, which include adequate shade in all paddocks to ensure animal comfort, particularly during warmer periods. Enhancements in shade provision are necessary.
#### **Required Infrastructure Improvements**
1. **Expand Water Infrastructure**: Critical for AMP grazing is the expansion of the water supply to ensure availability in each paddock, possibly requiring additional troughs and extended piping or mobile water systems.
2. **Enhance Fencing**: Investment in portable electric fencing or improvement of existing fences is essential to create smaller, manageable paddocks. This facilitates precise grazing control and the frequent movement of livestock inherent to AMP grazing.
3. **Implement Shade Solutions**: Developing portable or permanent shade structures in each paddock is necessary to protect livestock from heat stress, thereby maintaining animal welfare and productivity.
4. **Optimize Paddock Layout**: The design of paddocks should facilitate efficient livestock movement and access to essential resources (water, shade, supplemental feed/minerals), optimizing forage use and simplifying transitions between paddocks.
#### **Summary**
The foundational elements of water access and perimeter fencing at the Bennington farm provide a starting point for AMP grazing. However, significant infrastructure enhancements are essential to fully support this intensive grazing strategy. Improvements in water systems, fencing, shade provision, and paddock layout optimization are key to leveraging AMP grazing for enhanced pasture health, soil fertility, and animal welfare, aligning with the farm's organic and regenerative production goals.
                            `}
                        </Markdown>
                    </Box>
                </Collapse>
            </TableCell>
        </TableRow>
    </>
}

const Row2: FC = () => {
    const [open, setOpen] = useState(false);


    return <>
        <TableRow onClick={() => setOpen(!open)}>
            <TableCell key={0}>
                <IconButton
                    aria-label="expand row"
                    size="small"
                >
                    {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                </IconButton>
            </TableCell>
            <TableCell key={1}><Typography sx={{fontWeight: 'bold'}}>Open Grazing (Low Density)</Typography></TableCell>
            <TableCell key={3}>
                <Star/>
                <Star/>
                <Star/>
                <Star/>
            </TableCell>
        </TableRow>
        <TableRow style={{width: "100%"}}>
            <TableCell style={{paddingBottom: 0, paddingTop: 0, width: "100%", overflow: 'hidden'}} colSpan={4}>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <Box>
                        <Markdown>
                            {`
#### **Current Infrastructure**
- **Water Access**: The farm is equipped with water lines along its east border, essential for providing cattle with necessary hydration during open grazing. However, the distribution of water sources might need evaluation to ensure water is accessible across all grazing areas, especially in a less intensively managed open grazing system.
- **Fencing**: There is high-quality perimeter fencing, crucial for containing livestock within the grazing areas and preventing them from wandering into non-grazing lands or neighboring properties. This is a foundational requirement for any grazing system, including open grazing.
- **Shade**: Limited shade is available from trees near farm buildings. While this provides some relief for livestock, the distribution and availability of shade throughout the grazing area might be insufficient, especially in open grazing where animals are spread out.

#### **Required Infrastructure Improvements**
No major infrastructure investment is required for open grazing, though the following upgrades may allow for better management and results in an open grazing system: 
1. **Enhance Water Distribution**: To fully support open grazing, it's important to ensure all areas of the farm are well-served by water sources. Improving the water distribution system to make water accessible throughout all grazing areas is necessary.
2. **Implement Additional Shade Solutions**: Given the importance of animal welfare and the potential for heat stress in open grazing systems, implementing additional shade options across the farm is required. This could involve planting more trees or installing portable shade structures to provide adequate relief for livestock across the grazing area.

#### **Summary**
The Bennington farm's existing infrastructure of water access and perimeter fencing lays a solid foundation for open grazing. However, to optimize the farm for this grazing method, improvements in water distribution and shade provision are crucial. Addressing these specific areas will enhance the farm's suitability for open grazing, ensuring better animal welfare and supporting the overall sustainability and productivity of the grazing system.
                            `}
                        </Markdown>
                    </Box>
                </Collapse>
            </TableCell>
        </TableRow>
    </>
}

const Infrastructure: FC = () => {
    const [infrastructureItems, setInfrastructureItems] = useState<InfrastructureItemType[]>([])
    const [infrastructureSummary, setInfrastructureSummary] = useState<InfrastructureDescriptionType | null>(null)
    const [parcelBoundaryPaths, setParcelBoundaryPaths] = useState<LatLngCoordinatePathType[] | null>(null)
    const [parcelCentroid, setParcelCentroid] = useState<LatLngCoordinateType | null>(null)
    const [boundaryPolygons, setBoundaryPolygons] = useState<PolygonData[]>([])
    const {operation} = useOperation()

    useEffect(() => {
        if (operation) {
            const params = {operation_id: operation.id}
            axios.get('/infrastructure/items', {params})
                .then(response => {
                    setInfrastructureItems(response.data.items)
                    setInfrastructureSummary(response.data.summary)
                })
            axios.get('/accounts/operation-coords/', {params})
                .then(coordsInfo => {
                    setParcelBoundaryPaths(coordsInfo.data.boundaries.map((boundary: RawCoordinatePathType[]) => {
                        return rawToLatLngPath(boundary[0])
                    }))
                    setParcelCentroid(rawTolatLngCoord(coordsInfo.data.centroid))
                })
        }
    }, [operation]);

    useEffect(() => {
        if (infrastructureItems.length > 0 && parcelBoundaryPaths) {
            // const boundaries = infrastructureItems.map(infrastructureItem => {
            //     return infrastructureItem.boundaries.map(boundaryInstance => {
            //         console.log("infrastructureItem", infrastructureItem)
            //         console.log("boundaryInstance", boundaryInstance)
            //         return {
            //             paths: rawToLatLngPath(boundaryInstance),
            //             ...InfrastructureTypeMapConfig[infrastructureItem.type]
            //         }
            //     })
            // }).flat()
            setBoundaryPolygons(
                [
                    ...parcelBoundaryPaths.map((parcelBoundaryPath) => {
                        return {
                            paths: parcelBoundaryPath,
                            strokeColor: '#0000FF',
                            strokeOpacity: 0.8,
                            strokeWeight: 2,
                            fillColor: '#000000',
                            fillOpacity: 0.01,
                            // onClickFn: () => {alert('test')},
                        }
                    }),
                    ...infrastructureItems.map(infrastructureItem => {
                return infrastructureItem.boundaries.map(boundaryInstance => {
                    console.log("infrastructureItem", infrastructureItem)
                    console.log("boundaryInstance", boundaryInstance)
                    return {
                        paths: rawToLatLngPath(boundaryInstance),
                        ...InfrastructureTypeMapConfig[infrastructureItem.type]
                    }
                })
            }).flat()
                ]
            )
        }

    }, [infrastructureItems, parcelBoundaryPaths]);


    return (
        <>
            <Grid container spacing={2.5}>
                <Grid item width={'100%'}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link component={RouterLink} to={Paths.FULL_SURVEY.INDEX}>
                            Full Survey Report
                        </Link>
                        <Typography color="textPrimary">
                            Infrastructure
                        </Typography>
                    </Breadcrumbs>
                </Grid>
                <DashboardTitle title={"Infrastructure"}/>
                <Grid item width={'50%'}>
                    {infrastructureSummary ?
                        <TextBaseCard title={"Summary"}>
                            <Markdown>
                                {infrastructureSummary.summary}
                            </Markdown>
                        </TextBaseCard>
                        : <></>
                    }
                </Grid>
                <Grid item width={'50%'}>
                    {(boundaryPolygons && parcelCentroid)
                        ?
                        <Map key={operation ? operation.id : undefined} polygons={boundaryPolygons}
                             centroid={parcelCentroid}></Map>
                        :
                        <></>
                    }
                </Grid>
                <Grid item width={'100%'}>
                    {infrastructureSummary ?
                        <TextBaseCard title={"Details"}>
                            <Markdown>
                                {infrastructureSummary.details}
                            </Markdown>
                        </TextBaseCard>
                        : <></>
                    }
                </Grid>
                <Grid item width={'100%'}>
                    <Typography variant="h5">Practice Suitability</Typography>
                </Grid>
                <Grid item width={'100%'}>
                    <Card
                        sx={{
                            width: '100%',
                            background: '#FFFFFF'
                        }}
                    >
                        <TableContainer
                            sx={{
                                width: '100%',
                                overflowX: 'auto',
                                position: 'relative',
                                display: 'block',
                                maxWidth: '100%',
                                // '& td, & th': {whiteSpace: 'nowrap'}
                            }}
                        >
                            <Table aria-labelledby="tableTitle" sx={{
                                maxWidth: '100%',
                                tableLayout: "auto",
                                // width: "auto"
                            }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell key={0} sx={{width: '30px'}}>{""}</TableCell>
                                        <TableCell key={1}>Name</TableCell>
                                        <TableCell key={3}>Suitability Rating</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <Row1/>
                                    <Row2/>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Card>
                </Grid>
            </Grid>
        </>
    )
        ;
};

export default Infrastructure;