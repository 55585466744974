import React, {createContext, useContext, useState, useEffect, ReactNode} from 'react';
import {AuthContextType, AuthUser} from './AuthTypes';

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const useAuth = (): AuthContextType => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};

interface AuthProviderProps {
    children: ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({children}) => {
    const [currentUser, setCurrentUser] = useState<AuthUser | null>(null);

    useEffect(() => {
        const authToken = localStorage.getItem('authToken');
        const email = localStorage.getItem('email');
        const isStaff = localStorage.getItem('isStaff');

        if (authToken && email) {
            setCurrentUser({
                authToken: authToken,
                email: email,
                isStaff: JSON.parse(isStaff || 'false') === true,
            });
        }
    }, []);

    const login = (userData: AuthUser) => {
        localStorage.setItem('authToken', userData.authToken); // Save token
        localStorage.setItem('email', userData.email); // Save token
        localStorage.setItem('isStaff', userData.isStaff ? 'true' : 'false'); // Save token
        setCurrentUser(userData);
        return userData
    };

    const logout = () => {
        localStorage.removeItem('authToken');
        localStorage.removeItem('email');
        localStorage.removeItem('operationId');
        localStorage.removeItem('operationName');
        localStorage.removeItem('isStaff');

        setCurrentUser(null);
    };

    const value = {
        currentUser,
        login,
        logout
    };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};