import {LatLngCoordinatePathType, LatLngCoordinateType, RawCoordinatePathType, RawCoordinateType} from "../types/geo";


export const rawTolatLngCoord: (rawCoord: RawCoordinateType) => LatLngCoordinateType = (rawCoord) => {
    return {
        lng: rawCoord[0],
        lat: rawCoord[1]
    }
}

export const rawToLatLngPath: (rawPath: RawCoordinatePathType) => LatLngCoordinatePathType = (rawPath) => {
    return rawPath.map((rawCoord) => rawTolatLngCoord(rawCoord))
}
