import {Box, Breadcrumbs, Grid, Link, Stack, ToggleButton, ToggleButtonGroup} from "@mui/material";
import {Link as RouterLink} from "react-router-dom";
import Paths from "../../paths";
import Typography from "@mui/material/Typography";
import DashboardTitle from "../../components/DashboardTitle";
import {BaseCard, TextBaseCard} from "../../components/BaseCard";
import {useEffect, useMemo, useState} from "react";
import axios from "../../services/APIService";
import {
    Area,
    Bar,
    CartesianGrid,
    ComposedChart,
    Label,
    Legend,
    Line,
    ReferenceLine,
    ResponsiveContainer,
    Tooltip,
    TooltipProps,
    XAxis,
    YAxis
} from "recharts";
// for recharts v2.1 and above
import {NameType,} from 'recharts/types/component/DefaultTooltipContent';
import Markdown from "react-markdown";
import {useOperation} from "../../contexts/OperationContext";

type HistoricalWeatherMetricsType = {
    temperature_2m_max: number
    temperature_2m_mean: number
    temperature_2m_min: number
    precipitation_sum: number
}

interface HistoricalWeatherDataType extends HistoricalWeatherMetricsType {
    date: Date
}

type HistoricalWeatherMonthlyAveragesDataType = HistoricalWeatherMetricsType[]

type HistoricalWeatherChartDataType = {
    meanTemperature: number
    minTemperature: number
    maxTemperature: number
    temperatureRange: number[]
    precipitationSum: number
}

type HistoricalAveragesWeatherChartDataTypa = {
    historicalAvgMeanTemperature: number
    historicalAvgMinTemperature: number
    historicalAvgMaxTemperature: number
    historicalAvgTemperatureRange: number[]
    historicalAvgPrecipitationSum: number
}

interface WeeklyHistoricalWeather extends HistoricalWeatherChartDataType {
    weekOf: string
}

interface MonthlyHistoricalWeather extends HistoricalWeatherChartDataType, HistoricalAveragesWeatherChartDataTypa {
    monthOf: string
}

interface AnnualHistoricalWeather extends HistoricalWeatherChartDataType, HistoricalAveragesWeatherChartDataTypa {
    year: string
}

type HistoricalChartOptionType = 'monthly' | 'weekly' | 'annual';

function getPreviousMonthLength(date: Date): number {
    // Create a new Date object to avoid modifying the original date
    const tempDate = new Date(date.getTime());

    // Subtract one day to get the last day of the given month
    tempDate.setDate(tempDate.getDate() - 1);

    // Return the day of the month, which is the length of the month
    return tempDate.getDate();
}


const WeatherAndClimate = () => {
    const [historicalChartOption, setHistoricalChartOption] = useState<HistoricalChartOptionType>('weekly')
    const [weeklyStats, setWeeklyStats] = useState<WeeklyHistoricalWeather[]>([])
    const [monthlyStats, setMonthlyStats] = useState<MonthlyHistoricalWeather[]>([])
    const [annualStats, setAnnualStats] = useState<AnnualHistoricalWeather[]>([])
    const [operationSummary, setOperationSummary] = useState<string | null>(null)
    const {operation} = useOperation()

    useEffect(() => {
        if (operation) {
            const params = {operation_id: operation.id}
            axios.get('/weather/historical-weather', {params})
                .then(response => {
                        const historicalData = response.data.weather_data.historical_daily_data

                        const weatherData = historicalData.map((value: HistoricalWeatherDataType) => {
                            return {
                                date: new Date(value.date),
                                temperature_2m_mean: value.temperature_2m_mean,
                                temperature_2m_max: value.temperature_2m_max,
                                temperature_2m_min: value.temperature_2m_min,
                                precipitation_sum: value.precipitation_sum
                            }
                        })

                        setWeeklyStats(calculateWeeklyWeatherStats(weatherData))
                        setMonthlyStats(calculateMonthlyWeatherStats(weatherData, response.data.weather_data.monthly_averages))
                        setAnnualStats(calculateAnnualWeatherStats(weatherData, response.data.weather_data.annual_averages))

                        setOperationSummary(response.data.summary);
                    }
                )
        }
    }, [operation]);

    function calculateAnnualWeatherStats(data: HistoricalWeatherDataType[], historicalAverages: HistoricalWeatherDataType): AnnualHistoricalWeather[] {
        const annualWeather: AnnualHistoricalWeather[] = [];

        let year: number | null = null;
        let meanTemperatures: number[] = [];
        let minTemperatures: number[] = [];
        let maxTemperatures: number[] = [];
        let precipitationSums: number[] = [];

        data.forEach((entry, index) => {
            if (year === null) {
                year = entry.date.getUTCFullYear();
            }

            const dayOfMonth = entry.date.getDate();
            const monthOfYear = entry.date.getMonth();
            const isFirstDayOfYear = dayOfMonth === 1 && monthOfYear === 0;

            // Check is leap year
            const yearLength = new Date(year, 1, 29).getMonth() === 1 ? 366 : 365;

            if (isFirstDayOfYear && meanTemperatures.length > 0 && minTemperatures.length > 0 && maxTemperatures.length > 0) {
                // Calculate and push the average of the previous month
                const meanTemperature = Math.round(10 * meanTemperatures.reduce((acc, curr) => acc + curr, 0) / yearLength) / 10
                const minTemperature = Math.round(10 * minTemperatures.reduce((acc, curr) => Math.min(acc, curr))) / 10;
                const maxTemperature = Math.round(10 * maxTemperatures.reduce((acc, curr) => Math.max(acc, curr))) / 10;
                const temperatureRange = [minTemperature, maxTemperature]
                const precipitationSum = Math.round(10 * precipitationSums.reduce((acc, curr) => acc + curr, 0)) / 10;
                const historicalAvgMeanTemperature: number = historicalAverages["temperature_2m_mean"];
                const historicalAvgMinTemperature: number = historicalAverages["temperature_2m_min"];
                const historicalAvgMaxTemperature: number = historicalAverages["temperature_2m_max"];
                const historicalAvgTemperatureRange: number[] = [historicalAvgMinTemperature, historicalAvgMaxTemperature]
                const historicalAvgPrecipitationSum: number = historicalAverages["precipitation_sum"];
                annualWeather.push({
                    year: year.toString(),
                    meanTemperature,
                    minTemperature,
                    maxTemperature,
                    temperatureRange,
                    precipitationSum,
                    historicalAvgMeanTemperature,
                    historicalAvgMinTemperature,
                    historicalAvgMaxTemperature,
                    historicalAvgTemperatureRange,
                    historicalAvgPrecipitationSum,
                });

                // Reset for the next week
                meanTemperatures = [];
                minTemperatures = [];
                maxTemperatures = [];
                precipitationSums = [];
                year = entry.date.getFullYear();
            }

            meanTemperatures.push(entry.temperature_2m_mean);
            minTemperatures.push(entry.temperature_2m_min);
            maxTemperatures.push(entry.temperature_2m_max);
            precipitationSums.push(entry.precipitation_sum);

            // Handle the last week's average calculation
            if (index === data.length - 1) {
                const meanTemperature = Math.round(10 * meanTemperatures.reduce((acc, curr) => acc + curr, 0) / yearLength) / 10
                const minTemperature = Math.round(10 * minTemperatures.reduce((acc, curr) => Math.min(acc, curr))) / 10;
                const maxTemperature = Math.round(10 * maxTemperatures.reduce((acc, curr) => Math.max(acc, curr))) / 10;
                const temperatureRange = [minTemperature, maxTemperature]
                const precipitationSum = Math.round(10 * precipitationSums.reduce((acc, curr) => acc + curr, 0)) / 10;
                const historicalAvgMeanTemperature: number = historicalAverages["temperature_2m_mean"];
                const historicalAvgMinTemperature: number = historicalAverages["temperature_2m_min"];
                const historicalAvgMaxTemperature: number = historicalAverages["temperature_2m_max"];
                const historicalAvgTemperatureRange: number[] = [historicalAvgMinTemperature, historicalAvgMaxTemperature]
                const historicalAvgPrecipitationSum: number = historicalAverages["precipitation_sum"];
                annualWeather.push({
                    year: year.toString(),
                    meanTemperature,
                    minTemperature,
                    maxTemperature,
                    temperatureRange,
                    precipitationSum,
                    historicalAvgMeanTemperature,
                    historicalAvgMinTemperature,
                    historicalAvgMaxTemperature,
                    historicalAvgTemperatureRange,
                    historicalAvgPrecipitationSum,
                });
            }
        });
        return annualWeather;
    }

    function calculateMonthlyWeatherStats(data: HistoricalWeatherDataType[], historicalAverages: HistoricalWeatherMonthlyAveragesDataType): MonthlyHistoricalWeather[] {
        const monthlyWeather: MonthlyHistoricalWeather[] = [];

        let monthOf: Date | null = null;
        let meanTemperatures: number[] = [];
        let minTemperatures: number[] = [];
        let maxTemperatures: number[] = [];
        let precipitationSums: number[] = [];

        data.forEach((entry, index) => {
            if (monthOf === null) {
                monthOf = entry.date;
            }

            const dayOfMonth = entry.date.getDate();
            const isFirstDayOfMonth = dayOfMonth === 1;

            if (isFirstDayOfMonth && meanTemperatures.length > 0 && minTemperatures.length > 0 && maxTemperatures.length > 0) {
                const monthLength = getPreviousMonthLength(entry.date)
                // Calculate and push the average of the previous month
                const meanTemperature = Math.round(10 * meanTemperatures.reduce((acc, curr) => acc + curr, 0) / monthLength) / 10
                const minTemperature = Math.round(10 * minTemperatures.reduce((acc, curr) => Math.min(acc, curr))) / 10;
                const maxTemperature = Math.round(10 * maxTemperatures.reduce((acc, curr) => Math.max(acc, curr))) / 10;
                const temperatureRange = [minTemperature, maxTemperature]
                const precipitationSum = Math.round(10 * precipitationSums.reduce((acc, curr) => acc + curr, 0)) / 10;
                const historicalAvgMeanTemperature: number = historicalAverages[entry.date.getMonth()]["temperature_2m_mean"];
                const historicalAvgMinTemperature: number = historicalAverages[entry.date.getMonth()]["temperature_2m_min"];
                const historicalAvgMaxTemperature: number = historicalAverages[entry.date.getMonth()]["temperature_2m_max"];
                const historicalAvgTemperatureRange: number[] = [historicalAvgMinTemperature, historicalAvgMaxTemperature]
                const historicalAvgPrecipitationSum: number = historicalAverages[entry.date.getMonth()]["precipitation_sum"];
                monthlyWeather.push({
                    monthOf: `${monthOf.toLocaleString('default', {month: 'long'}).slice(0, 3)} ${monthOf.getFullYear()}`,
                    meanTemperature,
                    minTemperature,
                    maxTemperature,
                    temperatureRange,
                    precipitationSum,
                    historicalAvgMeanTemperature,
                    historicalAvgMinTemperature,
                    historicalAvgMaxTemperature,
                    historicalAvgTemperatureRange,
                    historicalAvgPrecipitationSum,
                });

                // Reset for the next week
                meanTemperatures = [];
                minTemperatures = [];
                maxTemperatures = [];
                precipitationSums = [];
                monthOf = entry.date;
            }

            meanTemperatures.push(entry.temperature_2m_mean);
            minTemperatures.push(entry.temperature_2m_min);
            maxTemperatures.push(entry.temperature_2m_max);
            precipitationSums.push(entry.precipitation_sum);

            // Handle the last week's average calculation
            if (index === data.length - 1) {
                const monthLength = getPreviousMonthLength(entry.date)
                // Calculate and push the average of the previous month
                const meanTemperature = Math.round(10 * meanTemperatures.reduce((acc, curr) => acc + curr, 0) / monthLength) / 10
                const minTemperature = Math.round(10 * minTemperatures.reduce((acc, curr) => Math.min(acc, curr))) / 10;
                const maxTemperature = Math.round(10 * maxTemperatures.reduce((acc, curr) => Math.max(acc, curr))) / 10;
                const temperatureRange = [minTemperature, maxTemperature]
                const precipitationSum = Math.round(10 * precipitationSums.reduce((acc, curr) => acc + curr, 0)) / 10;
                const historicalAvgMeanTemperature: number = historicalAverages[entry.date.getMonth()]["temperature_2m_mean"];
                const historicalAvgMinTemperature: number = historicalAverages[entry.date.getMonth()]["temperature_2m_min"];
                const historicalAvgMaxTemperature: number = historicalAverages[entry.date.getMonth()]["temperature_2m_max"];
                const historicalAvgTemperatureRange: number[] = [historicalAvgMinTemperature, historicalAvgMaxTemperature]
                const historicalAvgPrecipitationSum: number = historicalAverages[entry.date.getMonth()]["precipitation_sum"];
                monthlyWeather.push({
                    monthOf: `${monthOf.toLocaleString('default', {month: 'long'}).slice(0, 3)} ${monthOf.getFullYear()}`,
                    meanTemperature,
                    minTemperature,
                    maxTemperature,
                    temperatureRange,
                    precipitationSum,
                    historicalAvgMeanTemperature,
                    historicalAvgMinTemperature,
                    historicalAvgMaxTemperature,
                    historicalAvgTemperatureRange,
                    historicalAvgPrecipitationSum,
                });
            }
        });
        return monthlyWeather;
    }

    function calculateWeeklyWeatherStats(data: HistoricalWeatherDataType[]): WeeklyHistoricalWeather[] {
        const weeklyWeather: WeeklyHistoricalWeather[] = [];

        let weekOf: Date | null = null;
        let meanTemperatures: number[] = [];
        let minTemperatures: number[] = [];
        let maxTemperatures: number[] = [];
        let precipitationSums: number[] = [];

        data.forEach((entry, index) => {
            if (weekOf === null) {
                weekOf = entry.date;
            }

            const dayOfWeek = entry.date.getDay();
            const isFirstDayOfWeek = dayOfWeek === 0; // Consider Sunday as the first day of the week

            if (isFirstDayOfWeek && meanTemperatures.length > 0 && minTemperatures.length > 0 && maxTemperatures.length > 0) {
                // Calculate and push the average of the previous week
                const meanTemperature = Math.round(10 * meanTemperatures.reduce((acc, curr) => acc + curr, 0) / meanTemperatures.length) / 10;
                const minTemperature = Math.round(10 * minTemperatures.reduce((acc, curr) => Math.min(acc, curr))) / 10;
                const maxTemperature = Math.round(10 * maxTemperatures.reduce((acc, curr) => Math.max(acc, curr))) / 10;
                const temperatureRange = [minTemperature, maxTemperature]
                const precipitationSum = Math.round(10 * precipitationSums.reduce((acc, curr) => acc + curr, 0)) / 10;
                weeklyWeather.push({
                    weekOf: `${weekOf.getMonth() + 1}-${weekOf.getDate()}-${weekOf.getFullYear()}`,
                    meanTemperature,
                    minTemperature,
                    maxTemperature,
                    temperatureRange,
                    precipitationSum
                });

                // Reset for the next week
                meanTemperatures = [];
                minTemperatures = [];
                maxTemperatures = [];
                precipitationSums = [];
                weekOf = entry.date;
            }

            meanTemperatures.push(entry.temperature_2m_mean);
            minTemperatures.push(entry.temperature_2m_min);
            maxTemperatures.push(entry.temperature_2m_max);
            precipitationSums.push(entry.precipitation_sum);

            // Handle the last week's average calculation
            if (index === data.length - 1) {
                const meanTemperature = Math.round(10 * meanTemperatures.reduce((acc, curr) => acc + curr, 0) / meanTemperatures.length) / 10;
                const minTemperature = Math.round(10 * minTemperatures.reduce((acc, curr) => Math.min(acc, curr), 0)) / 10;
                const maxTemperature = Math.round(10 * maxTemperatures.reduce((acc, curr) => Math.max(acc, curr), 0)) / 10;
                const temperatureRange = [minTemperature, maxTemperature]
                const precipitationSum = Math.round(10 * precipitationSums.reduce((acc, curr) => acc + curr, 0)) / 10;
                weeklyWeather.push({
                    weekOf: `${weekOf.getMonth() + 1}-${weekOf.getDate()}-${weekOf.getFullYear()}`,
                    meanTemperature,
                    minTemperature,
                    maxTemperature,
                    temperatureRange,
                    precipitationSum
                });
            }
        });

        return weeklyWeather;
    }

    // const handleHistoricalChartOptionToggle = () => {
    //     if (historicalChartOption === 'weekly') {
    //         setHistoricalChartOption('monthly')
    //     } else {
    //         setHistoricalChartOption('weekly')
    //     }
    // }
    //
    const handleHistoricalChartOptionChange = (
        event: React.MouseEvent<HTMLElement>,
        newTimeframe: HistoricalChartOptionType
    ) => {
        setHistoricalChartOption(newTimeframe);
    }

    const CustomTooltip = ({active, payload, label}: TooltipProps<number[], NameType>) => {
        if (active && payload && payload.length && payload[0].value) {
            return (
                <div className="custom-tooltip" style={{background: 'white', padding: '8px'}}>
                    <Typography variant={'h5'}>{label}</Typography><Typography>{payload[0].value[0]}°F
                    to {payload[0].value[1]}°F</Typography>
                </div>
            );
        }

        return null;
    };

    const chartData = useMemo(() => {
        switch (historicalChartOption) {
            case 'weekly':
                return weeklyStats;
            case 'monthly':
                return monthlyStats;
            case 'annual':
                return annualStats;
            default:
                return [];
        }
    }, [historicalChartOption, weeklyStats, monthlyStats, annualStats]);

    return <>
        <Grid container spacing={2.5}>
            <Grid item width={'100%'}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link component={RouterLink} to={Paths.FULL_SURVEY.INDEX}>
                        Full Survey Report
                    </Link>
                    <Typography color="textPrimary">
                        Weather and Climate
                    </Typography>
                </Breadcrumbs>
            </Grid>
            <DashboardTitle title={"Weather and Climate"}/>
            <Grid item width={'100%'}>
                <TextBaseCard title={"Overview"}>
                    <Markdown>
                        {operationSummary}
                    </Markdown>
                </TextBaseCard>
            </Grid>
            <Grid item width={'100%'}>
                <BaseCard title={"Historical Data"}>
                    <Stack
                        direction="row"
                        spacing={1}
                        alignItems="center"
                        justifyContent={{xs: 'center', sm: 'flex-end'}}
                        sx={{mt: 3, mr: 2}}
                    >
                        <ToggleButtonGroup exclusive onChange={handleHistoricalChartOptionChange} size="small"
                                           value={historicalChartOption}>
                            <ToggleButton disabled={historicalChartOption === 'weekly'} value="weekly"
                                          sx={{px: 2, py: 0.5}}>
                                Weekly
                            </ToggleButton>
                            <ToggleButton disabled={historicalChartOption === 'monthly'} value="monthly"
                                          sx={{px: 2, py: 0.5}}>
                                Monthly
                            </ToggleButton>
                            <ToggleButton disabled={historicalChartOption === 'annual'} value="annual"
                                          sx={{px: 2, py: 0.5}}>
                                Annual
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Stack>
                    {
                        <Box style={{width: '100%', height: '500px'}}>
                            <ResponsiveContainer width="100%" height="100%">
                                <ComposedChart
                                    width={800}
                                    height={4}
                                    data={chartData}
                                    margin={{
                                        top: 20,
                                        right: 20,
                                        left: 20,
                                        bottom: 90,
                                    }}
                                >
                                    <CartesianGrid strokeDasharray="3 3"/>

                                    <XAxis
                                        dataKey={historicalChartOption === 'weekly' ? "weekOf" : historicalChartOption === 'monthly' ? "monthOf" : "year"}
                                        angle={90} textAnchor={"start"}>
                                        <Label
                                            value={historicalChartOption === 'weekly' ? "Week Of" : historicalChartOption === 'monthly' ? "Month" : "Year"}
                                            position="bottom"
                                            offset={60}
                                            style={{textAnchor: 'middle'}}
                                        />
                                    </XAxis>
                                    <YAxis yAxisId="left">
                                        <Label
                                            value="Temperature (°F)"
                                            position="insideLeft"
                                            offset={20}
                                            angle={-90}
                                            style={{textAnchor: 'middle'}}
                                        />
                                    </YAxis>
                                    <YAxis yAxisId="right" orientation="right">
                                        <Label
                                            value="Precipitation (in)"
                                            position="insideRight"
                                            offset={20}
                                            angle={-90}
                                            style={{textAnchor: 'middle'}}
                                        />
                                    </YAxis>
                                    <Tooltip content={<CustomTooltip/>}/>
                                    <Legend verticalAlign="top" height={36}/>
                                    <Area dataKey="temperatureRange" stroke="#3da866" fill="#3da866"
                                          fillOpacity={0.3} yAxisId="left"/>
                                    <Line dataKey="meanTemperature" yAxisId="left" stroke="#3da866"/>
                                    <Area dataKey="historicalAvgTemperatureRange" stroke="#FAB972" fill="#FAB972"
                                          fillOpacity={0.1} yAxisId="left"/>
                                    <Line dataKey="historicalAvgMeanTemperature" yAxisId="left" stroke="#FAB972"/>
                                    <Bar dataKey="precipitationSum" yAxisId="right" stroke="#8884d8" fill="#8884d8"
                                         fillOpacity={0.3}/>
                                    <Line dataKey="historicalAvgPrecipitationSum" yAxisId="right" stroke="#1AA7EC"/>
                                    <ReferenceLine y={32} stroke="red" strokeDasharray="3 3"
                                                   yAxisId="left" isFront={true}>
                                        <Label
                                            value={"Freezing (32°F)"}
                                            position={"insideBottomLeft"}
                                        />
                                    </ReferenceLine>
                                </ComposedChart>
                            </ResponsiveContainer>
                        </Box>
                        // </>
                        //
                        // :
                        // <></>
                    }
                    {/*</Markdown>*/}
                </BaseCard>
            </Grid>
        </Grid>
    </>
};

export default WeatherAndClimate;