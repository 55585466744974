import {
    Button,
    Chip,
    Divider,
    Grid,
    InputLabel,
    MenuItem,
    Modal,
    Select,
    SelectChangeEvent,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField
} from "@mui/material";
import {BaseCard} from "../../components/BaseCard";
import {convertKeysToCamelCase, customerFullName} from "../../utils/formatting";
import {ChangeEvent, useEffect, useState} from "react";
import axios from "../../services/APIService";
import {Operation} from "../../types/operation";
import Box from "@mui/material/Box";
import {Customer} from "../../types/customer";
import Dropzone from "../../components/DropzoneSingleFile";
import Typography from "@mui/material/Typography";

const Operations = () => {
    const [operationList, setOperationList] = useState<Operation[]>([])
    const [customerList, setCustomerList] = useState<Customer[]>([])
    const [onboardingModalOpen, setOnboardingModalOpen] = useState<boolean>(false)
    const [newOperationModalOpen, setNewOperationModalOpen] = useState<boolean>(false)
    const [onboardingOperation, setOnboardingOperation] = useState<Operation | null>(null)

    useEffect(() => {
        axios.get('/accounts/operation_list/')
            .then(response => {
                setOperationList(response.data.map((operation: Operation) => {
                    return {
                        ...operation,
                        customer: convertKeysToCamelCase(operation?.customer),
                    }
                }))
            })
        axios.get('/accounts/customer_list/')
            .then(response => {
                setCustomerList(response.data.map((customer: Customer) => convertKeysToCamelCase(customer)))
            })

    }, [])


    // New Operation Modal
    const handleOpenNewOperationModal = () => {
        setNewOperationModalOpen(true);
    }

    const handleCloseNewOperationModal = () => {
        setNewOperationModalOpen(false);
    }

    // Onboard Operation Modal
    const handleOpenOnboardingModal = (operation: Operation) => {
        setOnboardingOperation(operation)
        setOnboardingModalOpen(true);
    }

    const handleCloseOnboardingModal = () => {
        setOnboardingOperation(null)
        setOnboardingModalOpen(false)
    }

    const AddOperationButton = () => {
        return <Button onClick={handleOpenNewOperationModal} variant="outlined">Add Operation</Button>
    }

    const publishAnalysis = (operation: Operation) => {
        console.log('TODO: Publish and reload', operation)
    }

    const OnboardOperationModal = () => {
        interface FormDataState {
            parcelIds: string;
            file: File | null;
        }

        const [formData, setFormData] = useState<FormDataState>({
            parcelIds: '',
            file: null
        });

        const [isSubmitting, setIsSubmitting] = useState(false)

        const handleParcelIdsChange = (event: ChangeEvent<HTMLInputElement>) => {
            setFormData({...formData, parcelIds: event.target.value});
        };

        const handleSubmit = async (event: React.MouseEvent<HTMLAnchorElement>) => {
            event.preventDefault();
            setIsSubmitting(true);

            if (!onboardingOperation || !onboardingOperation.customer) {
                return
            }

            const formDataToSubmit = new FormData();
            formDataToSubmit.append('operation_id', onboardingOperation.id.toString());
            formDataToSubmit.append('state', 'KS');
            formDataToSubmit.append('parcel_ids', formData.parcelIds);
            if (formData.file) {
                formDataToSubmit.append('audio_file', formData.file);
            }

            try {
                const response = await axios.post('/context/onboard-context-report/', formDataToSubmit, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                console.log('Success:', response.data);
            } catch (error) {
                console.error('Error uploading:', error);
            }

            setIsSubmitting(false);
            window.location.reload()
        };

        const handleFileUpload = (file: File | null) => {
            if (file) {
                setFormData({ ...formData, file: file });
                console.log('File uploaded:', file);
            } else {
                setFormData({ ...formData, file: null });
                console.log('File cleared');
            }
            // Process files here (e.g., upload to a server)
        };

        if (!onboardingOperation || !onboardingOperation.customer) {
            return <></>
        }

        return <Modal open={onboardingModalOpen} onClose={handleCloseOnboardingModal}>
            <BaseCard title={"Onboard Operation"} sx={{
                position: 'absolute' as 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: {xs: `calc( 100% - 50px)`, sm: 'auto'},
                '& .MuiCardContent-root': {
                    overflowY: 'auto',
                    minHeight: 'auto',
                    maxHeight: `calc(100vh - 200px)`
                }
            }}>
                <Box
                    sx={{p: '24px', minWidth: '400px'}}
                >
                    <form>
                        <Grid width={'100%'}>
                            <Grid container width={'100%'}>
                                <Grid item width={'100%'} sx={{marginBottom: 2}}>
                                    <Typography sx={{fontWeight: 'bold'}} component={"span"}>Operation: </Typography>
                                    <Typography component={"span"}>{onboardingOperation.name}</Typography>
                                </Grid>
                                <Grid item width={'100%'} sx={{marginBottom: 2}}>
                                    <Typography sx={{fontWeight: 'bold'}} component={"span"}>Customer: </Typography>
                                    <Typography
                                        component={"span"}>{customerFullName(onboardingOperation.customer)}</Typography>
                                </Grid>
                                <Grid item width={'100%'} sx={{marginBottom: 2}}>
                                    <InputLabel id="operation-customer-select-label"
                                                sx={{marginBottom: 1}}>State</InputLabel>
                                    <Select
                                        labelId="operation-customer-select-label"
                                        id="operation-customer-select"
                                        value={"KS"}
                                        fullWidth={true}
                                    >
                                        <MenuItem key={1} value={'KS'}>Kansas</MenuItem>
                                        <MenuItem key={2} value={'NE'}>Nebraska</MenuItem>
                                        <MenuItem key={3} value={'IA'}>Iowa</MenuItem>
                                    </Select>
                                </Grid>
                                <Grid item width={'100%'} sx={{marginBottom: 2}}>
                                    <InputLabel id="operation-parcel-ids-label" sx={{marginBottom: 1}}>Parcel IDs (comma
                                        separated)</InputLabel>
                                    <TextField id="parcel-ids" variant="outlined" fullWidth={true} onChange={handleParcelIdsChange}/>
                                </Grid>
                                <Grid item width={'100%'} sx={{marginBottom: 2}}>
                                    <InputLabel id="operation-transcript-label" sx={{marginBottom: 1}}>Onboarding
                                        Audio</InputLabel>
                                    <Dropzone onFileUpload={handleFileUpload}
                                              instructions={"Drag and drop audio recording, or click to select file"}/>
                                </Grid>
                            </Grid>
                            <Divider sx={{marginY: 2}}/>
                            <Grid container justifyContent="space-between" alignItems="center">
                                <Grid item/>

                                <Grid item>
                                    <Stack direction="row" spacing={2} alignItems="center">
                                        <Button color="error" onClick={handleCloseOnboardingModal}>
                                            Cancel
                                        </Button>
                                        <Button type="submit" variant="contained" disabled={isSubmitting} onClick={handleSubmit} href={"#"}>
                                            Submit
                                        </Button>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                </Box>
            </BaseCard>
        </Modal>
    }


    const AddOperationModal = () => {
        const [operationCustomer, setOperationCustomer] = useState(customerList.length === 0 || !customerList[0].user ? "" : customerList[0].user.toString())
        const [isSubmitting, setIsSubmitting] = useState(false)

        const handleOperationCustomerChange = (event: SelectChangeEvent) => {
            setOperationCustomer(event.target.value as string)
        }

        const handleSubmit = () => {
            setIsSubmitting(true)
        }


        return <Modal open={newOperationModalOpen} onClose={handleCloseNewOperationModal}>
            <BaseCard title={"New Operation"} sx={{
                position: 'absolute' as 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: {xs: `calc( 100% - 50px)`, sm: 'auto'},
                '& .MuiCardContent-root': {
                    overflowY: 'auto',
                    minHeight: 'auto',
                    maxHeight: `calc(100vh - 200px)`
                }
            }}>
                <Box
                    sx={{p: '24px', minWidth: '400px'}}
                >
                    <form>
                        <Grid width={'100%'}>
                            <Grid container width={'100%'}>
                                <Grid item width={'100%'} sx={{marginBottom: 2}}>
                                    <InputLabel id="operation-name-label" sx={{marginBottom: 1}}>Operation
                                        Name</InputLabel>
                                    <TextField id="operation-name" variant="outlined" fullWidth={true}/>
                                </Grid>
                                <Grid item width={'100%'} sx={{marginBottom: 2}}>
                                    <InputLabel id="operation-customer-select-label"
                                                sx={{marginBottom: 1}}>Customer</InputLabel>
                                    <Select
                                        labelId="operation-customer-select-label"
                                        id="operation-customer-select"
                                        value={operationCustomer}
                                        onChange={handleOperationCustomerChange}
                                        fullWidth={true}
                                    >
                                        {
                                            customerList.map((customer, index) => {
                                                return <MenuItem key={index}
                                                                 value={customer.user}>{customerFullName(customer)}</MenuItem>
                                            })
                                        }
                                    </Select>
                                </Grid>
                            </Grid>
                            <Divider sx={{marginY: 2}}/>
                            <Grid container justifyContent="space-between" alignItems="center">
                                <Grid item/>
                                <Grid item>
                                    <Stack direction="row" spacing={2} alignItems="center">
                                        <Button color="error" onClick={handleCloseNewOperationModal}>
                                            Cancel
                                        </Button>
                                        <Button type="submit" variant="contained" disabled={isSubmitting}
                                                onClick={handleSubmit}>
                                            Add
                                        </Button>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                </Box>
            </BaseCard>
        </Modal>
    }

    return <>
        <Grid item width={'100%'}>
            <BaseCard title={"Operations"} secondary={<AddOperationButton/>}>
                <TableContainer
                    sx={{
                        width: '100%',
                        overflowX: 'auto',
                        position: 'relative',
                        display: 'block',
                        maxWidth: '100%',
                    }}
                >
                    <Table aria-labelledby="tableTitle" sx={{
                        maxWidth: '100%',
                        tableLayout: "auto",
                        // width: "auto"
                    }}>
                        <TableHead>
                            <TableRow>
                                <TableCell key={1}>Name</TableCell>
                                <TableCell key={3}>Customer</TableCell>
                                <TableCell key={4}>Hub</TableCell>
                                <TableCell key={5}>Status</TableCell>
                                <TableCell key={6}>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {operationList.map((operation, index) => {
                                return <TableRow key={index}>
                                    <TableCell key={1}>{operation.name}</TableCell>
                                    <TableCell
                                        key={3}>{operation.customer ? customerFullName(operation.customer) : ""}</TableCell>
                                    <TableCell key={4}>{operation.hub}</TableCell>
                                    <TableCell key={5}>
                                        {
                                            operation.status === 'ACTIVE' ?
                                                <Chip color={'success'} label='Active'/>
                                                : operation.status === 'COLLECTING_DATA' ?
                                                    <Chip color={'warning'} label='Collecting Data'/>
                                                    : operation.status === 'TRANSCRIBING_ONBOARDING' ?
                                                        <Chip color={'warning'} label='Transcribing Onboarding'
                                                              variant="outlined"/>
                                                        : operation.status === 'AWAITING_PUBLISHING' ?
                                                            <Chip color={'warning'} label='Awaiting Publishing'/>
                                                            : operation.status === 'PENDING_ONBOARDING' ?
                                                                <Chip color={'error'} label='Pending Onboarding'/>
                                                                : ""

                                        }
                                    </TableCell>
                                    <TableCell key={6}>
                                        {
                                            operation.status === 'ACTIVE' ?
                                                <Button disabled={true}>No Action Needed</Button>
                                                : operation.status === 'COLLECTING_DATA' ?
                                                    <Button disabled={true}>No Action Needed</Button>
                                                    : operation.status === 'TRANSCRIBING_ONBOARDING' ?
                                                        <Button disabled={true}>No Action Needed</Button>
                                                        : operation.status === 'AWAITING_PUBLISHING' ?
                                                            <Button variant="outlined"
                                                                    onClick={() => publishAnalysis(operation)}>Publish</Button>
                                                            : operation.status === 'PENDING_ONBOARDING' ?
                                                                <Button variant="outlined"
                                                                        onClick={() => handleOpenOnboardingModal(operation)}>Onboard</Button>
                                                                : ""

                                        }
                                    </TableCell>
                                </TableRow>
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </BaseCard>
        </Grid>
        <AddOperationModal/>
        <OnboardOperationModal/>
    </>
}

export default Operations