import {FC} from "react";
import {ColorProps} from "../types/extended";
import {useTheme} from "@mui/material/styles";
import getColors from "../utils/getColors";
import Box from "@mui/material/Box";

const Dot: FC<{ color: ColorProps }> = ({color}) => {
    const theme = useTheme();
    const colors = getColors(theme, color || 'primary');
    const {main} = colors;

    return (
        <Box
            // component="span"
            sx={{
                width: 8,
                height: 8,
                borderRadius: '50%',
                bgcolor: main
                // ...sx
            }}
        />
    );
};

export default Dot