import React from 'react';
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography} from '@mui/material';
import {
    AnnotatedValue,
    AnnotatedValueCalculationInput,
    CategoryTS,
    FinancialDataTS,
    SubcategoryTS
} from "../../types/financialData/PLData";
import formatCurrencyWithParens from "../formatting/formatCurrencyWithParens";


const ValueCell: React.FC<{
    value: number | AnnotatedValue,
    padding?: string,
    fontWeight?: string,
    valueCellCallback: (category: string, year: string, explanation: string, value: number, previousValue: number | null, calculationInputs: AnnotatedValueCalculationInput[]) => void,
    year: string,
    category: string,
    previousValue: number | AnnotatedValue | null,
}> = ({
          value,
          padding = "0px",
          fontWeight = "400",
          valueCellCallback,
          year,
          category,
          previousValue
      }) => {
    if (typeof value === 'number') {
        return (
            <TableCell sx={{textAlign: 'right', paddingRight: padding}}>
                <Typography fontWeight={fontWeight}>{formatCurrencyWithParens(value)}</Typography>
            </TableCell>
        )
    }
    let color = "#3da866"
    if (value.type && value.type === "warning") {
        color = "#ed691c"
    }
    // Check if there is a previous value, and if so extract the number (value)
    const previousValueNumber = previousValue === null ? null : typeof previousValue === "number" ? previousValue : previousValue.value
    const calculationInputs = value.calculation ? value.calculation.inputs : []
    return (
        // eslint-disable-next-line react/jsx-no-undef
        <TableCell sx={{textAlign: 'right', paddingRight: padding}}
                   onClick={() => valueCellCallback(category, year, value.annotation, value.value, previousValueNumber, calculationInputs)}>
            <Tooltip title={<Typography>{value.annotation}</Typography>} arrow>
                <Typography color={color} fontWeight={fontWeight}>{formatCurrencyWithParens(value.value)}</Typography>
            </Tooltip>
        </TableCell>
    );
};

// Component to display a subcategory
const SubcategoryRow: React.FC<{
        subcategory: SubcategoryTS,
        valueCellCallback: (category: string, year: string, explanation: string, value: number, previousValue: number | null, calculationInputs: AnnotatedValueCalculationInput[]) => void,
        years: string[]
    }> = ({subcategory, valueCellCallback, years}) => (
        <TableRow>
            <TableCell><Typography sx={{paddingLeft: '24px'}}>{subcategory.name}</Typography></TableCell>
            {subcategory.totals.map((value, index) => {
                    // Check what the previous value was
                    const previousValue = index > 0 ? subcategory.totals[index - 1] : null
                    return <ValueCell
                        value={value} padding={'24px'}
                        valueCellCallback={valueCellCallback}
                        year={years[index]}
                        category={subcategory.name}
                        previousValue={previousValue}
                    />
                }
            )}
        </TableRow>
    )
;

const CategoryRows: React.FC<{
    category: CategoryTS,
    valueCellCallback: (category: string, year: string, explanation: string, value: number, previousValue: number | null, calculationInputs: AnnotatedValueCalculationInput[]) => void,
    years: string[]
}> = ({category, valueCellCallback, years}) => (
    <>
        <TableRow>
            <TableCell><Typography
                sx={{paddingLeft: '16px', fontWeight: '800'}}>{category.name}</Typography></TableCell>
            {category.totals.map((value, index) => {
                const previousValue = index > 0 ? category.totals[index - 1] : null
                return <ValueCell
                    value={value}
                    padding={'16px'}
                    fontWeight={'800'}
                    valueCellCallback={valueCellCallback}
                    category={category.name}
                    year={years[index]}
                    previousValue={previousValue}
                />
            })}
        </TableRow>
        {category.subcategories && category.subcategories.map((subcategory, index) => (
            <SubcategoryRow key={index} subcategory={subcategory} valueCellCallback={valueCellCallback} years={years}/>
        ))}
    </>
);
// Main component to display the P&L table
const PLTable: React.FC<{
    financialData: FinancialDataTS,
    valueCellCallback: (category: string, year: string, explanation: string, value: number, previousValue: number | null, calculationInputs: AnnotatedValueCalculationInput[]) => void,
}> = ({
          financialData,
          valueCellCallback
      }) => (
    <TableContainer>
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell></TableCell>
                    {financialData.year.map((year, index) => (
                        <TableCell key={index} sx={{textAlign: 'right'}}><Typography
                            fontWeight="bold">{year}</Typography></TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                <TableRow sx={{backgroundColor: '#f5f5f5'}}>
                    <TableCell><Typography fontWeight={800}>Net Income</Typography></TableCell>
                    {financialData.net_income.map((value, index) => {
                        const previousValue = index > 0 ? financialData.net_income[index - 1] : null
                        return <ValueCell
                            value={value}
                            valueCellCallback={valueCellCallback}
                            year={financialData.year[index]}
                            category={"Net Income"}
                            previousValue={previousValue}
                        />
                    })}
                </TableRow>
                <TableRow sx={{backgroundColor: '#f5f5f5'}}>
                    <TableCell><Typography fontWeight={600}>Revenues</Typography></TableCell>
                    {financialData.revenues.totals.map((value, index) => {
                        const previousValue = index > 0 ? financialData.revenues.totals[index - 1] : null
                        return <ValueCell
                            value={value}
                            fontWeight={'800'}
                            valueCellCallback={valueCellCallback}
                            year={financialData.year[index]}
                            category={"Revenues"}
                            previousValue={previousValue}
                        />
                    })}
                </TableRow>
                {financialData.revenues.categories.map((category, index) => (
                    <CategoryRows key={index} category={category} valueCellCallback={valueCellCallback}
                                  years={financialData.year}/>
                ))}
                <TableRow sx={{backgroundColor: '#f5f5f5'}}>
                    <TableCell><Typography fontWeight={600}>Costs</Typography></TableCell>
                    {financialData.costs.totals.map((value, index) => {
                        const previousValue = index > 0 ? financialData.costs.totals[index - 1] : null
                        return <ValueCell
                            value={value}
                            fontWeight={'800'}
                            valueCellCallback={valueCellCallback}
                            year={financialData.year[index]}
                            category={"Costs"}
                            previousValue={previousValue}
                        />
                    })}
                </TableRow>
                {financialData.costs.categories.map((category, index) => (
                    <CategoryRows key={index} category={category} valueCellCallback={valueCellCallback}
                                  years={financialData.year}/>
                ))}
            </TableBody>
        </Table>
    </TableContainer>
);

export default PLTable;
