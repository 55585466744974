import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import * as React from "react";
import axios from "../services/APIService";
import Button from "@mui/material/Button";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import {useOperation} from "../contexts/OperationContext";


const MarketingCallMenu = () => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const triggerCall = (name: string) => {
        axios.get(`/operator_interface/make_marketing_call/${name}/`)
        handleClose()
    }

    return (
        <div>
            <Button
                id="marketing-call-button"
                aria-controls={open ? 'marketing-call-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                variant="outlined"
                disableElevation
                onClick={handleClick}
                endIcon={<KeyboardArrowDownIcon/>}
                size={"small"}
                sx={{marginRight: "10px"}}
            >
                Trigger Marketing Call
            </Button>
            <Menu
                elevation={0}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                id="marketing-call-menu"
                MenuListProps={{
                    'aria-labelledby': 'marketing-call-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                sx={{zIndex: 9001}}
            >
                <MenuItem onClick={(e) => triggerCall("rhys")} disableRipple>
                    Rhys
                </MenuItem>
                <MenuItem onClick={(e) => triggerCall("dakota")} disableRipple>
                    Dakota
                </MenuItem>
            </Menu>
        </div>
    );
}

const ManualCallMenu = () => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const triggerCall = (name: string) => {
        axios.get(`/operator_interface/make_demo_call/${name}/`)
        handleClose()
    }

    return (
        <div>
            <Button
                id="demo-call-button"
                aria-controls={open ? 'demo-call-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                variant="outlined"
                disableElevation
                onClick={handleClick}
                endIcon={<KeyboardArrowDownIcon/>}
                size={"small"}
                sx={{marginRight: "10px"}}
            >
                Trigger Wrap-Up Call
            </Button>
            <Menu
                elevation={0}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                id="demo-call-menu"
                MenuListProps={{
                    'aria-labelledby': 'demo-call-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                sx={{zIndex: 9001}}
            >
                <MenuItem onClick={(e) => triggerCall("rhys")} disableRipple>
                    Rhys
                </MenuItem>
                <MenuItem onClick={(e) => triggerCall("dakota")} disableRipple>
                    Dakota
                </MenuItem>
            </Menu>
        </div>
    );
}

const ResetDataButton = () => {
    const {operation} = useOperation()
    const triggerReset = () => {
        if (operation) {
            const params = {operation_id: operation.id}
            axios.get('/operator_interface/reset_demo_data/', {params}).then(() => window.location.reload())
        }
    }

    return (
        <div>
            <Button
                id="demo-customized-button"
                variant="outlined"
                disableElevation
                onClick={triggerReset}
                size={"small"}
                sx={{marginRight: "10px"}}
            >
                Reset Data
            </Button>
        </div>
    );
}

const AdminFooter = () => {
    return (
        <AppBar position="fixed" sx={{top: 'auto', bottom: 0, zIndex: 9000, backgroundColor: "#e6f3ec"}}>
            <Toolbar>
                <Typography color={"#068e43"} variant={"h5"} sx={{marginRight: "20px"}}>Demo Manager</Typography>
                <ManualCallMenu/>
                <MarketingCallMenu/>
                <ResetDataButton/>
            </Toolbar>
        </AppBar>
    );
}

export default AdminFooter;
