import React, {createContext, ReactNode, useContext, useEffect, useState} from 'react';
import {Customer} from "../types/customer";
import axios from "../services/APIService";
import {useAuth} from "../auth/AuthContext";
import {customerFullName} from "../utils/formatting";

interface CustomerProviderProps {
    children: ReactNode;
}

interface CustomerContextType {
    customer: Customer | null;
    setCustomer: (customer: Customer | null) => void;
    getCustomer: () => Promise<Customer>;
    fullName: () => string;
}


const CustomerContext = createContext<CustomerContextType | undefined>(undefined);

export const CustomerProvider: React.FC<CustomerProviderProps> = ({ children }) => {
    const [customer, setCustomer] = useState<Customer | null>(null);
    const {currentUser} = useAuth();

    const getCustomer = () => axios.get('/accounts/customer_info')
        .then(response => {
        const customerInfo = response.data;
        const newCustomer: Customer = {
          firstName: customerInfo.first_name,
          lastName: customerInfo.last_name,
          operations: customerInfo.operations
        };
        setCustomer(newCustomer);
        return newCustomer;  // Return the new customer object for chaining
      });

    const fullName = () => customer ? customerFullName(customer) : "";

    useEffect(() => {
        if (currentUser && !currentUser.isStaff) {
            getCustomer();
        }
    }, [currentUser])

    return (
        <CustomerContext.Provider value={{ customer, setCustomer, getCustomer, fullName }}>
            {children}
        </CustomerContext.Provider>
    );
};


export const useCustomer = () => {
    const context = useContext(CustomerContext);
    if (context === undefined) {
        throw new Error('useCustomer must be used within a CustomerProvider');
    }
    return context;
}