import Typography from '@mui/material/Typography';
import {Breadcrumbs, Dialog, DialogContent, DialogContentText, DialogTitle, Grid, Link} from '@mui/material';
import {useEffect, useState} from "react";
import DashboardTitle from "../../../components/DashboardTitle";
import {Link as RouterLink} from "react-router-dom";
import Paths from "../../../paths";
import {BaseCard} from "../../../components/BaseCard";
import {useOperation} from "../../../contexts/OperationContext";
import Box from "@mui/material/Box";
import {AnnotatedValueCalculationInput, FinancialDataTS} from "../../../types/financialData/PLData";
import PLTable from "../../../components/financialData/PLTable";
import formatCurrencyWithParens from "../../../components/formatting/formatCurrencyWithParens";

const CALCULATION_INPUT_RATING_TO_COLOR = {
    "HIGH": "error",
    "MEDIUM": "warning",
    "LOW": "success",
}

type DetailModalInfoType = {
    category: string
    year: string
    explanation: string
    value: number
    previousValue: number | null
    calculationInputs?: AnnotatedValueCalculationInput[]
}

const financialData: FinancialDataTS = {
    "year": ['Baseline', "Projected", "Actual"],
    "net_income": [-12170.88, 401.08,
        {
            value: 851.08,
            annotation: "Higher net income than expected due to lower hay costs"
        }

    ],
    "costs": {
        "totals": [-20715.48, -26218.92, {
            value: -25768.92,
            annotation: "Fed 5 fewer bales of hay than expected (0.25 per cow) due to pasture feeding longer into winter"
        }],
        "categories": [
            {
                "name": "Per Cow",
                "totals": [-10365.48, -10688.92, -10238.22],
                "subcategories": [
                    {
                        "name": "Harvested Forage (Hay)",
                        "totals": [
                            -4860,
                            -3960,
                            {
                                value: -3410,
                                annotation: "Fed 5 fewer bales of hay than expected (0.25 per cow) due to pasture feeding longer into winter"
                            },
                        ]
                    },
                    {
                        "name": "Grain/Protein Supplements",
                        "totals": [0, 0, 0]
                    },
                    {
                        "name": "Mineral",
                        "totals": [-102.6, -125.4, -125.4]
                    }, {
                        "name": "Other Feed",
                        "totals": [0, 0, 0]
                    }, {
                        "name": "Replacement Females",
                        "totals": [-5402.88, -6603.52, -6603.52]
                    }
                ]
            },
            {
                "name": "Per Operation / Herd",
                "totals": [-7200, -12380, -12380],
                "subcategories": [
                    {
                        "name": "Annual Bull Charge or A.I.",
                        "totals": [-3000, -3000, -3000]
                    },
                    {
                        "name": "Other Livestock Breeding/Marketing",
                        "totals": [0, 0, 0]
                    },
                    {
                        "name": "Utilities",
                        "totals": [-300, -300, -300]
                    },
                    {
                        "name": "Gas, Fuel, Oil",
                        "totals": [-300, -300, -300]
                    },
                    {
                        "name": "Machinery, Facility/Equipment Repairs",
                        "totals": [-900, -900, -900]
                    },
                    {
                        "name": "Cash Interest Paid",
                        "totals": [-400, -400, -400]
                    },
                    {
                        "name": "Other variable costs",
                        "totals": [-200, -200, -200]
                    },
                    {
                        "name": "Labor (Hired & Operator)",
                        "totals": [-2100, 7280, 7280]
                    },
                ]
            },
            {
                "name": "Per Acre",
                "totals": [-3150, -3150, -3150],
                "subcategories": [
                    {
                        "name": "Pasture Seeding",
                        "totals": [-3150, -3150, -3150]
                    },
                ]
            }
        ]
    },
    "revenues": {
        "totals": [8544.6, 26620, 26620],
        "categories": [
            {
                "name": "Animal Sales",
                "totals": [8544.6, 26620, 26620],
                "subcategories": [
                    {
                        "name": "Weaned Calf Sales",
                        "totals": [5232.6, 22572, 22572]
                    },
                    {
                        "name": "Cull Cows Sales",
                        "totals": [3312, 4048, 4048]
                    },
                ]
            }
        ]
    }
}


const PlanningFinances = () => {
    const {operation} = useOperation()

    const [detailModalOpen, setDetailModalOpen] = useState<boolean>(false);
    const [detailModalInfo, setDetailModalInfo] = useState<DetailModalInfoType | null>(null)

    const handleCloseDetailModal = () => {
        setDetailModalOpen(false)
    }

    const valueCellCallback = (category: string, year: string, explanation: string, value: number, previousValue: number | null, calculationInputs: AnnotatedValueCalculationInput[]) => {
        setDetailModalInfo({
            category: category,
            year: year,
            explanation: explanation,
            value: value,
            previousValue: previousValue,
            calculationInputs: calculationInputs,
        })
        setDetailModalOpen(true)
    }

    const NumberDetailsModal = () => {
        if (detailModalInfo !== null) {

            const ValueEl = () => {
                if (detailModalInfo.previousValue !== null) {
                    return (<DialogContentText>
                        <Typography variant={"h5"}
                                    component={"span"}>{formatCurrencyWithParens(detailModalInfo.previousValue)} </Typography>
                        to
                        <Typography variant={"h4"}
                                    component={"span"}> {formatCurrencyWithParens(detailModalInfo.value)}</Typography>
                    </DialogContentText>)
                } else {
                    return <DialogContentText>

                        <Typography variant={"h4"}
                                    component={"span"}>{detailModalInfo.calculationInputs ? "=" : ""} {formatCurrencyWithParens(detailModalInfo.value)}</Typography>
                    </DialogContentText>
                }
            }

            const CalculationEl = () => {
                if (detailModalInfo.calculationInputs) {
                    return <>
                        {
                            detailModalInfo.calculationInputs.map((input, index) => {
                                if (input.annotation) {
                                    const color = CALCULATION_INPUT_RATING_TO_COLOR[input.rating]
                                    return <Box>
                                        {/* TODO: DEMO: Reenable tooltip */}
                                        {/*<MuiTooltop title={*/}
                                        {/*    <Typography>{input.annotation}</Typography>*/}
                                        {/*} arrow>*/}
                                            <Typography variant={"h6"} component={"span"} sx={{marginBottom: "4px"}} color={`${color}.main`}>{index > 0 ? "* " : ""} {input.format(input.value)} {input.unit}</Typography>
                                        {/*</MuiTooltop>*/}
                                    </Box>
                                } else {
                                    return <Box><Typography variant={"h5"} component={"span"}>{index > 0 ? "* " : ""} {input.format(input.value)} {input.unit}</Typography></Box>
                                }
                            })
                        }
                    </>
                } else {
                    return <></>
                }
            }

            return <Dialog
                open={detailModalOpen && detailModalInfo !== null}
                onClose={handleCloseDetailModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box>
                    <DialogTitle id="modal-modal-title">
                        <Typography variant={"h3"}>
                            {`${detailModalInfo.year}: ${detailModalInfo.category}`}
                        </Typography>
                    </DialogTitle>
                    <DialogContent id="modal-modal-description">
                        <CalculationEl/>
                        <ValueEl/>
                        <DialogContentText sx={{marginTop: '16px'}}>
                            {detailModalInfo.explanation}
                        </DialogContentText>
                    </DialogContent>
                </Box>
            </Dialog>
        } else {
            return <></>
        }
    }

    useEffect(() => {
        if (operation) {
            // const params = {operation_id: operation.id}
            // axios.get('/planning/finances', {params})
            //     .then(response => {
            //     })
        }
    }, [operation]);

    return (
        <>
            <Grid container spacing={2.5}>
                <Grid item width={'100%'}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link component={RouterLink} to={Paths.RESULTS.INDEX}>
                            Results
                        </Link>
                        <Typography color="textPrimary">
                            Finances
                        </Typography>
                    </Breadcrumbs>
                </Grid>
                <DashboardTitle title={"Finances"}/>
                <Grid item width={"100%"}>
                    <BaseCard title={"Profit and Loss Results (2024/25"}>
                        <PLTable financialData={financialData} valueCellCallback={valueCellCallback}/>
                    </BaseCard>
                </Grid>
            </Grid>
            <NumberDetailsModal/>
        </>
    )
}

export default PlanningFinances