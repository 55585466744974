import Typography from '@mui/material/Typography';
import {Breadcrumbs, Grid, Link, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@mui/material';
import {FC, useEffect, useState} from "react";
import DashboardTitle from "../../components/DashboardTitle";
import {Link as RouterLink} from "react-router-dom";
import Paths from "../../paths";
import {BaseCard, TextBaseCard} from "../../components/BaseCard";
import Markdown from "react-markdown";
import LinearProgressWithScoreAndTooltip from "../../components/LinearProgressWithScoreAndTooltop";
import {useOperation} from "../../contexts/OperationContext";
import axios from "../../services/APIService";
import {convertKeysToCamelCase} from "../../utils/formatting";

interface MarketInfoType {
    name: string;
    premiumScore: number;
    logisticalEaseScore: number;
    operationalReadinessScore: number;
    overallScore: number;
    noteOnPremium: string | null
    noteOnLogisticalEase: string | null
    noteOnOperationalReadiness: string | null
    noteOnOverallScore: string | null
}

interface TableCellWithScoreProps {
    score: number;
    tooltipText: string | null;
}


const TableCellWithScore: FC<TableCellWithScoreProps> = ({score, tooltipText}) => {
    return <TableCell>
        <LinearProgressWithScoreAndTooltip score={score} tooltipText={tooltipText}/>
    </TableCell>

}

const Row: FC<MarketInfoType> = ({
                                     name,
                                     premiumScore,
                                     logisticalEaseScore,
                                     operationalReadinessScore,
                                     overallScore,
                                     noteOnPremium,
                                     noteOnLogisticalEase,
                                     noteOnOperationalReadiness,
                                     noteOnOverallScore
                                 }) => {
    return <TableRow>
        <TableCell key={1}>{name}</TableCell>
        <TableCellWithScore score={premiumScore}
                            tooltipText={noteOnPremium}/>
        <TableCellWithScore score={logisticalEaseScore}
                            tooltipText={noteOnLogisticalEase}/>
        <TableCellWithScore score={operationalReadinessScore}
                            tooltipText={noteOnOperationalReadiness}/>
        <TableCellWithScore score={overallScore}
                            tooltipText={noteOnOverallScore}/>
    </TableRow>
}

const Markets: FC = () => {
    const [marketsSummary, setMarketsSummary] = useState<string>('')
    const [potentialMarkets, setPotentialMarkets] = useState<MarketInfoType[]>([])
    const {operation} = useOperation()

    useEffect(() => {
        if (operation) {
            const params = {operation_id: operation.id}
            axios.get('/markets/', {params})
                .then(response => {
                    setPotentialMarkets(convertKeysToCamelCase(response.data.potential_markets))
                    setMarketsSummary(response.data.summary.summary)
                })
        }
    }, [operation]);

    return (
        <>
            <Grid container spacing={2.5}>
                <Grid item width={'100%'}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link component={RouterLink} to={Paths.FULL_SURVEY.INDEX}>
                            Full Survey Report
                        </Link>
                        <Typography color="textPrimary">
                            Markets
                        </Typography>
                    </Breadcrumbs>
                </Grid>
                <DashboardTitle title={"Markets"}/>
                <Grid item width={'100%'}>
                    <TextBaseCard title={"Summary"}>
                        <Markdown>
                            {marketsSummary}
                        </Markdown>
                    </TextBaseCard>
                </Grid>
                <Grid item width={'100%'}>
                    <BaseCard title={"Markets"}>
                        <TableContainer
                            sx={{
                                width: '100%',
                                overflowX: 'auto',
                                position: 'relative',
                                display: 'block',
                                maxWidth: '100%',
                            }}
                        >
                            <Table aria-labelledby="tableTitle" sx={{
                                maxWidth: '100%',
                                tableLayout: "auto",
                                // width: "auto"
                            }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell key={1}>Name</TableCell>
                                        <TableCell key={3}>Premium</TableCell>
                                        <TableCell key={4}>Logistical Ease</TableCell>
                                        <TableCell key={5}>Operational Readiness</TableCell>
                                        <TableCell key={6}>Overall Score</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        potentialMarkets ?
                                            potentialMarkets.map((potentialMarket, index) => {
                                                return <Row
                                                    key={index}
                                                    name={potentialMarket.name}
                                                    logisticalEaseScore={potentialMarket.logisticalEaseScore}
                                                    operationalReadinessScore={potentialMarket.operationalReadinessScore}
                                                    premiumScore={potentialMarket.premiumScore}
                                                    overallScore={potentialMarket.overallScore}
                                                    noteOnLogisticalEase={potentialMarket.noteOnLogisticalEase}
                                                    noteOnOperationalReadiness={potentialMarket.noteOnOperationalReadiness}
                                                    noteOnPremium={potentialMarket.noteOnPremium}
                                                    noteOnOverallScore={potentialMarket.noteOnOverallScore}
                                                />
                                            })
                                            : <></>
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </BaseCard>
                </Grid>
            </Grid>
        </>
    )
        ;
};

export default Markets;