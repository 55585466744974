import Typography from '@mui/material/Typography';
import {Breadcrumbs, Grid, Link, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@mui/material';
import {FC, useEffect, useState} from "react";
import DashboardTitle from "../../components/DashboardTitle";
import {Link as RouterLink} from "react-router-dom";
import Paths from "../../paths";
import {BaseCard, TextBaseCard} from "../../components/BaseCard";
import Markdown from "react-markdown";
import TableCellWithScore from 'components/TableCellWithScore';
import {useOperation} from "../../contexts/OperationContext";
import axios from "../../services/APIService";
import {convertKeysToCamelCase} from "../../utils/formatting";

interface OperatorType {
    firstName: string;
    lastName: string;
    experienceScore: number;
    comfortWithLeaseScore: number;
    distanceScore: number;
    overallScore: number;
    operation: number;
}

interface OperatorRowType {
    firstName: string;
    lastName: string;
    experienceScore: number;
    comfortWithLeaseScore: number;
    distanceScore: number;
    overallScore: number;
    currentTenant?: boolean
}

const OperatorRow: FC<OperatorRowType> = ({
                                              firstName,
                                              lastName,
                                              experienceScore,
                                              comfortWithLeaseScore,
                                              distanceScore,
                                              overallScore,
                                              currentTenant = false
                                          }) => {
    return <TableRow>
        <TableCell key={1}>
            {
                currentTenant
                    ?
                    <Typography fontWeight={900}>{firstName} {lastName} (Current Tenant)</Typography>
                    :
                    <Typography>{firstName} {lastName}</Typography>
            }
        </TableCell>

        <TableCellWithScore score={experienceScore}/>
        <TableCellWithScore score={comfortWithLeaseScore}/>
        <TableCellWithScore score={distanceScore}/>
        <TableCellWithScore score={overallScore}/>
    </TableRow>
}

const Operators: FC = () => {
    const [operators, setOperators] = useState<OperatorType[]>([])
    const [operatorSummary, setOperatorSummary] = useState<string>("")
    const {operation} = useOperation()

    useEffect(() => {
        if (operation) {
            const params = {operation_id: operation.id}
            axios.get('/operators/list/', {params})
                .then(response => {
                    setOperators(convertKeysToCamelCase(response.data.operators))
                    setOperatorSummary(response.data.summary.summary)
                })
        }
    }, [operation]);

    return (
        <>
            <Grid container spacing={2.5}>
                <Grid item width={'100%'}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link component={RouterLink} to={Paths.FULL_SURVEY.INDEX}>
                            Full Survey Report
                        </Link>
                        <Typography color="textPrimary">
                            Operators
                        </Typography>
                    </Breadcrumbs>
                </Grid>
                <DashboardTitle title={"Operators"}/>
                <Grid item width={'100%'}>
                    <TextBaseCard title={"Summary"}>
                        <Markdown>
                            {operatorSummary}
                        </Markdown>
                    </TextBaseCard>
                </Grid>
                <Grid item width={'100%'}>
                    <BaseCard title={"Certification-based Markets"}>
                        <TableContainer
                            sx={{
                                width: '100%',
                                overflowX: 'auto',
                                position: 'relative',
                                display: 'block',
                                maxWidth: '100%',
                            }}
                        >
                            <Table aria-labelledby="tableTitle" sx={{
                                maxWidth: '100%',
                                tableLayout: "auto",
                                // width: "auto"
                            }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell key={1}>Name</TableCell>
                                        <TableCell key={2}>Experience</TableCell>
                                        <TableCell key={3}>Comfort with Lease</TableCell>
                                        <TableCell key={4}>Distance</TableCell>
                                        <TableCell key={5}>Overall Score</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        operation && operators.length > 0 ?
                                            operators.map((operator, index) => {
                                                return <OperatorRow firstName={operator.firstName}
                                                                    lastName={operator.lastName}
                                                                    experienceScore={operator.experienceScore}
                                                                    comfortWithLeaseScore={operator.comfortWithLeaseScore}
                                                                    distanceScore={operator.distanceScore}
                                                                    overallScore={operator.overallScore}
                                                                    currentTenant={operator.operation === operation?.id}
                                                                    key={index}
                                                />
                                            })
                                            : <></>
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </BaseCard>
                </Grid>
            </Grid>
        </>
    )
        ;
};

export default Operators;