import React from 'react';
import {Loader} from '@googlemaps/js-api-loader';
import {LatLngCoordinateType, PolygonData} from "../types/geo";

interface PropertyMapProps {
    centroid?: LatLngCoordinateType;
    polygons?: PolygonData[];
}


class Map extends React.Component<PropertyMapProps> {
    map: google.maps.Map | null = null;
    polygons: google.maps.Polygon[] = [];

    componentDidMount() {
        this.loadMap();
    }

    componentDidUpdate(prevProps: PropertyMapProps) {
        if (this.props.centroid && this.props.centroid !== prevProps.centroid) {
            this.map?.setCenter(this.props.centroid);
        }

        if (this.props.polygons !== prevProps.polygons) {
            this.updatePolygons(this.props.polygons);
        }
    }

    loadMap() {
        const {centroid} = this.props;
        if (!centroid) return;

        const mapOptions = {
            zoom: 16,
            center: centroid,
            mapTypeId: 'satellite',
            tilt: 55,
            mapId: '1816442228e400c5',
            heading: 45
        };

        const loader = new Loader({
            apiKey: 'AIzaSyCBx6DGO93v790Tcj1r_4kU6XNBnNgW4qw',
            version: 'weekly',
        });

        loader.importLibrary('maps').then(() => {
            this.map = new google.maps.Map(document.getElementById('map') as HTMLElement, mapOptions);
            this.updatePolygons(this.props.polygons);
        }).catch(error => {
            console.error("Failed to load the Google Maps script:", error);
        });
    }

    updatePolygons(polygonsData?: PolygonData[]) {
        // Remove old polygons
        this.polygons.forEach(polygon => polygon.setMap(null));
        this.polygons = [];

        if (!this.map || !polygonsData) return;

        // Create new polygons
        this.polygons = polygonsData.map(data => {
            const polygon = new google.maps.Polygon({
                paths: data.paths,
                strokeColor: data.strokeColor || '#FF0000',
                strokeOpacity: data.strokeOpacity || 0.8,
                strokeWeight: data.strokeWeight || 2,
                fillColor: data.fillColor || '#FF0000',
                fillOpacity: data.fillOpacity || 0.35,
            });
            polygon.setMap(this.map);

            // Store the original fill color
            const originalFillColor = polygon.get('fillColor');

            // Add mouseover event to change fill color
            google.maps.event.addListener(polygon, 'mouseover', () => {
                polygon.setOptions({fillColor: '#00FF00', fillOpacity: 0.5}); // Change to your hover color
                if (data.mouseoverFn) {
                    data.mouseoverFn()
                }
            });

            // Add mouseout event to revert to original fill color
            google.maps.event.addListener(polygon, 'mouseout', () => {
                polygon.setOptions({fillColor: originalFillColor, fillOpacity: 0.35});
                if (data.mouseoutFn) {
                    data.mouseoutFn()
                }
            });

            // Add mouseout event to revert to original fill color
            google.maps.event.addListener(polygon, 'click', () => {
                if (data.onClickFn) {
                    data.onClickFn()
                }
            });


            return polygon;
        });
    }

    componentWillUnmount() {
        if (this.map) {
            google.maps.event.clearInstanceListeners(this.map);
        }
    }

    render() {
        return <div id="map" style={{height: '500px', width: '100%', margin: 'auto'}}></div>;
    }
}

export default Map;


// import React from 'react';
// import { Loader } from '@googlemaps/js-api-loader';
// import { LatLngCoordinatePathType, LatLngCoordinateType } from "../types/geo";
//
// interface PropertyMapProps {
//     boundary?: LatLngCoordinatePathType;
//     centroid?: LatLngCoordinateType;
// }
//
// class Map extends React.Component<PropertyMapProps> {
//     map: google.maps.Map | null = null;
//     boundaryPolygon: google.maps.Polygon | null = null;
//
//     componentDidMount() {
//         this.loadMap();
//     }
//
//     componentDidUpdate(prevProps: PropertyMapProps) {
//         if (this.props.centroid && this.props.centroid !== prevProps.centroid) {
//             this.map?.setCenter(this.props.centroid);
//         }
//
//         if (this.props.boundary !== prevProps.boundary && this.props.boundary) {
//             this.updateBoundary(this.props.boundary);
//         }
//     }
//
//     loadMap() {
//         const { centroid } = this.props;
//         if (!centroid) return;
//
//         const mapOptions = {
//             zoom: 16,
//             center: centroid,
//             mapTypeId: 'satellite',
//             tilt: 55,
//             mapId: '1816442228e400c5',
//             heading: 45
//         };
//
//         const loader = new Loader({
//             apiKey: 'AIzaSyCBx6DGO93v790Tcj1r_4kU6XNBnNgW4qw',
//             version: 'weekly',
//         });
//
//         loader
//             .importLibrary('maps')
//             .then(() => {
//             this.map = new google.maps.Map(document.getElementById('map') as HTMLElement, mapOptions);
//             this.createBoundary();
//         }).catch(error => {
//             console.error("Failed to load the Google Maps script:", error);
//         });
//     }
//
//     createBoundary() {
//         if (!this.map || !this.props.boundary) return;
//
//         this.boundaryPolygon = new google.maps.Polygon({
//             paths: this.props.boundary,
//             strokeColor: '#0000FF',
//             strokeOpacity: 0.8,
//             strokeWeight: 2,
//             fillColor: '#00FF00',
//             fillOpacity: 0.1,
//         });
//
//         this.boundaryPolygon.setMap(this.map);
//     }
//
//     updateBoundary(newBoundary: LatLngCoordinatePathType) {
//         if (!this.boundaryPolygon) {
//             this.createBoundary();
//         } else {
//             this.boundaryPolygon.setPaths(newBoundary);
//         }
//     }
//
//     componentWillUnmount() {
//         if (this.map) {
//             google.maps.event.clearInstanceListeners(this.map);
//         }
//     }
//
//     render() {
//         return (
//             <div id="map" style={{ height: '500px', width: '100%', margin: 'auto' }}></div>
//         );
//     }
// }
//
// export default Map;