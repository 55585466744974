import {Customer} from "../types/customer";

export function toCamelCase(str: string): string {
    return str.replace(/([-_][a-z])/ig, ($1) => $1.toUpperCase().replace('_', ''));
}

export function convertKeysToCamelCase(obj: any): any {
    if (Array.isArray(obj)) {
        return obj.map(item => convertKeysToCamelCase(item));
    } else if (obj !== null && obj.constructor === Object) {
        return Object.keys(obj).reduce((result, key) => {
            result[toCamelCase(key)] = convertKeysToCamelCase(obj[key]);
            return result;
        }, {} as any);
    }
    return obj;
}

export function convertSnakeToTitleCase(text: string): string {
    return text
        .split("_")
        .map(
            word => {
                return word
                    .split("")
                    .map(
                        (character, index) => {
                            return index === 0 ? character.toUpperCase() : character.toLowerCase()
                        })
                    .join("")
            })
        .join(" ")
}

export const customerFullName = (customer: Customer) => `${customer.firstName} ${customer.lastName}`

export const formatDateAsMonthDay = (date: Date) => {
    const month = date.getUTCMonth() + 1; // getMonth() returns 0-based month, so we add 1
    const day = date.getUTCDate();

    return `${month}/${day}`;
}

export const formatNumberAsPercent = (value: number, decimalPlaces: number = 0): string => {
    // Multiply the value by 100 to convert it to a percentage
    const percentValue = value * 100;

    // Format the number to the specified decimal places and add the '%' sign
    return `${percentValue.toFixed(decimalPlaces)}%`;
}