import Login from "./accounts/Login";
import Box from "@mui/material/Box";


const Index: React.FC = () => {
    return (
        <div>
            {/*<Header/>*/}
            <Box sx={{
                marginTop: '50px'
            }}>
                <Login/>
            </Box>
        </div>
    );
};

export default Index;